import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    getSystemSettings,
    tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// settings context
import { SettingsContext } from "../../../../contexts/Settings";

//pages & includes
import Templatesidebar from "./Templatesidebar";


const Mentionslegales = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //getting context values here
    let { templateOneSettings, loading,generalSettings } =
        useContext(SettingsContext);

    // state starts here
    const [showPageData, setshowPageData] = useState('');
    

    // onchange function
    const handleOnChange = (e) => {
        e.persist();
        setshowPageData({
            ...showPageData,
            [e.target.name]: e.target.value
        })
    }
    // onchange function
    const handleOnChangeEditor = (editdata) => {        
        setshowPageData({
            ...showPageData,
            'content': editdata
        })
    }

    // use effect
    useEffect(() => {
        const url = BASE_URL + `/website/pages/mentions-legales`;
        axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {        
            setshowPageData(res.data[0]);
        });
    }, []);

   
    // handle submit
    const handlesubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();        
        formData.append("name", showPageData.name);
        formData.append("content", showPageData.content);        
        formData.append("slug", 'mentions-legales');
        
        const url = BASE_URL + "/settings/store-cms-section";
        axios.post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            if (res.data.status == 200) {
                toast.success(`${_t(t("updated successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            } else if (res.data.status == 422) {
                toast.error(`${_t(t("Try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            }
        });
    };

    const editorConfiguration = {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote','alignment' ],
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
            ]
        }
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("Nous contacter"))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <Templatesidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0 ">
                            <div className="t-bg-white">
                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide t-pt-50"
                                    data-simplebar
                                >
                                    <div className="t-pl-15 t-pr-15">

                                        <form onSubmit={handlesubmit} className="row card p-2 mx-3 mb-5 sm-text">
                                            <div className="col-12">
                                            <div className="form-group mt-3">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">{_t(t("Title"))}<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            type="text"
                                                            onChange={handleOnChange} className="form-control" name="name" placeholder="" required="" value={showPageData.name} />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">{_t(t("Content"))}<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        
                                                            <CKEditor
                                                                    editor={ ClassicEditor }
                                                                    data={showPageData.content}
                                                                    onReady={ editor => {
                                                                        // You can store the "editor" and use when it is needed.
                                                                        console.log( 'Editor is ready to use!', editor );
                                                                    } }
                                                                    onChange={ ( event, editor ) => {
                                                                        const data = editor.getData();
                                                                        console.log( { event, editor, data } );
                                                                        handleOnChangeEditor(data);
                                                                    } }
                                                                    onBlur={ ( event, editor ) => {
                                                                        console.log( 'Blur.', editor );
                                                                    } }
                                                                    onFocus={ ( event, editor ) => {
                                                                        console.log( 'Focus.', editor );
                                                                    } }
                                                                    config={ editorConfiguration }

                                                                />
                                                    </div>
                                                </div>

                                       
                                                <div className="form-group mt-5 pb-2">
                                                    <div className="col-lg-12">
                                                        <button className="btn btn-primary px-5" type="submit">{_t(t("Save"))}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default Mentionslegales;
