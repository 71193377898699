import React, { useContext, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import {
  _t,
  getCookie,
  getSystemSettings,  
} from "../../../functions/Functions";

import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//3rd party packages
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../contexts/Settings";
import "react-toastify/dist/ReactToastify.css";
//jQuery initialization
import $ from "jquery";
const ThankYou = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let {   generalSettings, } =  useContext(SettingsContext);
  const [loading, setLoading] = useState(true);
  const [contactContent, setContactContent] = useState({
    contacttitle: getSystemSettings(generalSettings, "contacttitle"),        
    contactcontent: getSystemSettings(generalSettings, "contactcontent"),        
    contacttime: getSystemSettings(generalSettings, "contacttime"),        
    contactaddress: getSystemSettings(generalSettings, "contactaddress"),        
    contactfacilityone: getSystemSettings(generalSettings, "contactfacilityone"),        
    contactfacilitytwo: getSystemSettings(generalSettings, "contactfacilitytwo"),        
    contactadminemail: getSystemSettings(generalSettings, "contactadminemail"),  
    contactmap: getSystemSettings(generalSettings, "contactmap"),                
});

const [contactForm, setContactForm] = useState({
    name: null,        
    email:null,        
    subject: null,        
    message: null,            
});

// onchange function
const handleOnChange = (e) => {
    e.persist();
    setContactForm({
        ...contactForm,
        [e.target.name]: e.target.value
    })
}

useEffect(() => {
    
    const url = BASE_URL + `/settings/general-settings`;
    axios.get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    }).then((res) => {        
        setContactContent({
            contacttitle: getSystemSettings(res.data, "contacttitle"),        
            contactcontent: getSystemSettings(res.data, "contactcontent"),        
            contacttime: getSystemSettings(res.data, "contacttime"),        
            contactaddress: getSystemSettings(res.data, "contactaddress"),        
            contactfacilityone: getSystemSettings(res.data, "contactfacilityone"),        
            contactfacilitytwo: getSystemSettings(res.data, "contactfacilitytwo"),        
            contactadminemail: getSystemSettings(res.data, "contactadminemail"),  
            contactmap: getSystemSettings(res.data, "contactmap"),                
        })
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    });
    
}, []);

const handlesubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();        
    formData.append("name", contactForm.name);
    formData.append("email", contactForm.email);
    formData.append("subject", contactForm.subject);
    formData.append("message", contactForm.message);    
    
    const url = BASE_URL + "/website/sentcontactmail";
    axios.post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
    }).then((res) => {
        if (res.data.status == 200) {
            toast.success(`${_t(t("Email sent successfully"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
            $('#contact-form').trigger('reset');

        } else if (res.data.status == 422) {
            toast.error(`${_t(t("Try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });

        }
    });
};

  return (
    <>

<div className={`preloader02 ${!loading && "d-none"}`} id="preloader02">
        <div className="preloader-inner">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
      <main  className="kh-user pt-5 mb-none menu-area home2-menu-area ${loading?'d-none':''}">
        <div className="container">
          
          
<section class="mb-4">

    <div class="gc_contacttitle border-0">
    <h2 class="h1-responsive font-weight-bold text-center my-4">{_t(t("Thank You"))}</h2>
    
    <p class="text-center w-responsive mx-auto">
    {_t(t("Thanks so much for your order! I hope you enjoy your new purchase! Please reach out to me at"))} {contactContent.contactadminemail} {_t(t("if you need anything."))}<br/>
    <NavLink
                        to="/my-orders"
                        className="btn btn-primary px-5 mt-3"
                      >
                        
                        <span className="kh-user__text text-capitalize sm-text">
                          {_t(t("My Orders"))}
                        </span>
                      </NavLink>
    </p>
    </div>
   

</section>

          
        </div>
        
      </main>
     

    </>
  );
};

export default ThankYou;
