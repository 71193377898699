import React, {  useEffect, useContext, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import {
  BASE_URL,
  SAAS_APPLICATION,
  saas_apiUrl,
  saas_apiParams,
  saas_form_data
} from "../../../../BaseUrl";

//functions
import { _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  modalLoading,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
  getSystemSettings, } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import beepsound from "../../../../beep.mp3";

//importing context consumer here
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";

const KitchenOnline = () => {
  const { t } = useTranslation();
  let { authUserInfo } = useContext(UserContext);
  //getting context values here
  const {
    //common    
    generalSettings,
  } = useContext(SettingsContext);

  let staffBranchId = authUserInfo.details !== null &&
    authUserInfo.details.user_type;
    const { getDeliveryUser, deliveryForSearch } = useContext(UserContext);
  const {
    //kitchen dashboard
    branchList,
    getKitchenNewOrdersOnline,
    kithcenNewOrdersOnline,
    setKithcenNewOrdersOnline,
    loading,
    setLoading,
  } = useContext(RestaurantContext);

  
  const {
    //common
    onlineOrdersAdmin,
    setOnlineOrdersAdmin,
    onlineOrdersAdminForSearch,
    setOnlineOrdersAdminForSearch,
    getOnlineOrders,
    setPaginatedOnlineOrders,
    foodGroupForSearch,
    dataPaginating,
  } = useContext(FoodContext);

  const componentRef = useRef();
  const component2Ref = useRef();

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    cancel: false,
    time_to_deliver: null,
    delivery_man_id: null,
    uploading: false,
  });

  // set delivery men name
  const [delivery_man_name, setDelivery_man_name] = useState(null);

  const filterDeliveryName = (e) => deliveryForSearch &&
    deliveryForSearch.filter((each) => {
      if (each.id == e.target.value) {
        setDelivery_man_name(each.name)
      }
    })

  //search result
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });

  // set new reference id
  const [orderReference, setorderReference] = useState(null);
  //state hooks
  const [filterOrder, setFilterOrder] = useState({
    isFiltered: false,
    filterKey: "",
    groups: null,
  });

  const [filterBranchwise, setFilterBranchwise] = useState({
    isFiltered: false,
    branchGroups: null,
  });

  
  useEffect(() => {
    //get all orders when coming to kithcen
    getKitchenNewOrdersOnline();
    getDeliveryUser();       
    //add "All" option to group filter
    let tempFoodGroups = [];
    foodGroupForSearch &&
      foodGroupForSearch.map((item) => {
        if (item.name !== "All") {
          tempFoodGroups.push(item);
        }
      });
    //new option added to food group
    tempFoodGroups.unshift({ name: "All" });
    setFilterOrder({
      ...filterOrder,
      groups: tempFoodGroups,
    });

    // add all option to branch
    let tempBranches = [];
    branchList.data && branchList.data.map((item) => {
      if (branchList.data.name !== "All") {
        tempBranches.push(item);
      }
    });

    // new option added to branch list
    tempBranches.unshift({ name: "All" });
    setFilterBranchwise({
      ...filterBranchwise,
      branchGroups: tempBranches,
    });

    //check online orders
  const interval = setInterval(() => {
    const pendingUrl = BASE_URL + "/website/pending-order";  
    let oldorderconut = document.getElementsByClassName("gcspapprovebuttonwaper").length;        
      return axios
        .get(pendingUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log(oldorderconut+'-'+res.data);  
          if (oldorderconut<res.data) {
            
            toast.success(`${_t(t("New order has been received"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            getKitchenNewOrdersOnline();
            setSearchedOrder({
              ...searchedOrder,
              searched: false,
            });
            //setOnlineCounter(res.data);
            // let ordernew = document.getElementById("myAudioOrder"); 
            // ordernew.muted = false;              
            // ordernew.play();
            
            setTimeout(() => {
              let song = new Audio(beepsound);                        
              song.muted=false;
              song.play();  
            }, 1000);
            
            
          }
        })
        .catch((err) => { });

        
    
  }, 5000);



  }, [foodGroupForSearch, branchList]);

  //submit accept order request
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const url = BASE_URL + "/website/accept-order";
    let formData = {
      time_to_deliver: checkOrderDetails.time_to_deliver,
      delivery_man_id: checkOrderDetails.delivery_man_id,
      id: checkOrderDetails.item.id,
    };
    return axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {
        let temp = checkOrderDetails.item;
        temp.is_accepted = 1;
        temp.time_to_deliver = checkOrderDetails.time_to_deliver;
        setCheckOrderDetails({
          ...checkOrderDetails,
          item: temp,
          time_to_deliver: null,
          reason_of_cancel: null,
          delivery_man_id: null,
          settle: false,
        });
        handleOnlyPrint();
        setLoading(false);

        // send saas request
        // saas send vat 
        if (SAAS_APPLICATION == 'YES') {

          // accept order
          const url = saas_apiUrl + '/api/restaurants/order/is_accepted?' + saas_apiParams;
          let saasFormData = new FormData();
          saasFormData.append("reference", checkOrderDetails.item.reference);
          saasFormData.append("is_accepted", 1);

          axios.post(url, saasFormData).then((res) => {
            return "data send to saas app"
          }).catch(() => {
            return 'something went wrong'
          });

          // delivery man details
          const url2 = saas_apiUrl + '/api/restaurants/order/delivery_boy?' + saas_apiParams;
          let saasFormData2 = new FormData();

          saasFormData2.append("reference", checkOrderDetails.item.reference);
          saasFormData2.append("delivery_man", delivery_man_name);
          saasFormData2.append("time_to_deliver", checkOrderDetails.time_to_deliver);

          axios.post(url2, saasFormData2).then((res) => {
            return "data send to saas app"
          }).catch(() => {
            return 'something went wrong'
          });

          // delivery time details
          const url3 = saas_apiUrl + '/api/restaurants/order/delivery_boy/deliver?' + saas_apiParams;
          let saasFormData3 = new FormData();
          saasFormData2.append("reference", checkOrderDetails.item.reference);
          // make it dynamic
          // there will 3 options 1.on the way 2.pending 3.accepted
          // on deliverymen dashboard
          saasFormData3.append("delivery_status", "on the way");
          axios.post(url3, saasFormData3).then((res) => {
            return "data send to saas app"
          }).catch(() => {
            return 'something went wrong'
          });



        }
        // end here
      })
      .catch((err) => { });
  };

  //submit cancel order request
  const handleSubmitCancel = (e) => {
    e.preventDefault();
    setLoading(true);
    const url = BASE_URL + "/website/cancel-order";
    let formData = {
      reason_of_cancel: checkOrderDetails.reason_of_cancel,
      id: checkOrderDetails.item.id,
    };
    return axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {
        let temp = checkOrderDetails.item;
        temp.is_cancelled = 1;
        temp.reason_of_cancel = checkOrderDetails.reason_of_cancel;
        setCheckOrderDetails({
          ...checkOrderDetails,
          item: temp,
          reason_of_cancel: null,
          cancel: false,
        });
        setLoading(false);
        getKitchenNewOrdersOnline();
      })
      .catch((err) => { });
  };

  //print here
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        handleOnlyPrint2();
      }
    },
  });

  //for kithcen
  const handleOnlyPrint2 = useReactToPrint({
    content: () => component2Ref.current,
  });

  //filter ordered items
  const handleFilter = (foodGrp) => {
    setFilterOrder({
      ...filterOrder,
      isFiltered: foodGrp.name == "All" ? false : true,
      filterKey: foodGrp.name,
    });
  };
  // filter ordered items by branch
  const handleBranchFilter = (branch) => {
    setFilterBranchwise({
      ...filterBranchwise,
      isFiltered: branch.name === "All" ? false : true,
      branchId: branch.id,
      branchName: branch.name,
    })
  }

  //search orders here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedOrder({ ...searchedOrder, searched: false });
    } else {
      let searchedList =
        kithcenNewOrdersOnline &&
        kithcenNewOrdersOnline.filter((item) => {
          //token
          let lowerCaseItemToken = item.token;

          return lowerCaseItemToken.includes(searchInput);
        });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedList,
        searched: true,
      });
    }
  };

  //accept or reject
  const handleAcceptOrReject = (id) => {
    //front end accept-reject view update
    let newState = kithcenNewOrdersOnline.map((orderItem) =>
      orderItem.id === id
        ? {
          ...orderItem,
          is_accepted_by_kitchen:
            parseInt(orderItem.is_accepted_by_kitchen) === 0 ? 1 : 0,
        }
        : orderItem
    );
    setKithcenNewOrdersOnline(newState);

    //front end accept-reject view update for searched
    if (searchedOrder.searched) {
      let newStateSearched = searchedOrder.list.map((orderItemSearched) =>
        orderItemSearched.id === id
          ? {
            ...orderItemSearched,
            is_accepted_by_kitchen:
              parseInt(orderItemSearched.is_accepted_by_kitchen) === 0
                ? 1
                : 0,
          }
          : orderItemSearched
      );
      setSearchedOrder({
        ...searchedOrder,
        list: newStateSearched,
      });
    }

    //set on server
    const url = BASE_URL + "/settings/accept-new-order-online";
    let formData = {
      id,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => { })
      .catch(() => {
        //undo if any error happened
        newState = newState.map((orderItem) =>
          orderItem.id === id
            ? {
              ...orderItem,
              is_accepted_by_kitchen:
                parseInt(orderItem.is_accepted_by_kitchen) === 0 ? 1 : 0,
            }
            : orderItem
        );
        setKithcenNewOrdersOnline(newState);
        //undo if any error happened for searched
        if (searchedOrder.searched) {
          let newStateSearched = searchedOrder.list.map((orderItemSearched) =>
            orderItemSearched.id === id
              ? {
                ...orderItemSearched,
                is_accepted_by_kitchen:
                  parseInt(orderItemSearched.is_accepted_by_kitchen) === 0
                    ? 1
                    : 0,
              }
              : orderItemSearched
          );
          setSearchedOrder({
            ...searchedOrder,
            list: newStateSearched,
          });
        }
        toast.error(`${_t(t("Please refresh and try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //delete confirmation modal of waiter
  const handleReadyConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("All items are cooked?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleReady(id);
                  onClose();
                }}
              >
                {_t(t("YES, COOKED!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("NO"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //make the order group ready here
  const handleReady = (id) => {
    setLoading(true);
    const url = BASE_URL + "/settings/mark-all-items-ready-online";
    let formData = {
      id,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => {
        //remove ready item from order list
        let newState = kithcenNewOrdersOnline.filter((orderItem) => {

          if (SAAS_APPLICATION == "YES") {
            if (orderItem.id == id) {
              setorderReference(orderItem.reference);
            }
          }
          return orderItem.id !== id;
        });

        if (SAAS_APPLICATION == 'YES') {

          // accept order
          const url = saas_apiUrl + '/api/restaurants/order/is_ready?' + saas_apiParams;
          let saasFormData = new FormData();
          saasFormData.append("reference", orderReference);
          saasFormData.append("is_ready", 1);

          axios.post(url, saasFormData).then((res) => {
            return "data send to saas app"
          }).catch(() => {
            return 'something went wrong'
          });
        }
        setKithcenNewOrdersOnline(newState);

        //remove ready item from search list
        if (searchedOrder.searched) {
          let newSearchState = searchedOrder.list.filter(
            (orderItemSearched) => {
              return orderItemSearched.id !== id;
            }
          );
          setSearchedOrder({
            ...searchedOrder,
            list: newSearchState,
          });
        }


        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please refresh and try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //make the order group ready here
  const handleEachItemReady = (orderGroupId, itemId) => {
    //to redo the action
    let oldState = kithcenNewOrdersOnline;
    let oldSearchedState = searchedOrder.list;

    //new state
    let orderGroup = kithcenNewOrdersOnline.find((orderItem) => {
      return orderItem.id === orderGroupId;
    });

    //updating the item's cooking status
    let newItems = orderGroup.orderedItems.map((eachItem) =>
      eachItem.id === itemId
        ? {
          ...eachItem,
          is_cooking: parseInt(eachItem.is_cooking) === 0 ? 1 : 0,
        }
        : eachItem
    );

    //set updated order list with item's status change
    let newState = kithcenNewOrdersOnline.map((orderItem) =>
      orderItem.id === orderGroupId
        ? { ...orderItem, is_accepted_by_kitchen: 1, orderedItems: newItems }
        : orderItem
    );
    setKithcenNewOrdersOnline(newState);

    //searched list update
    if (searchedOrder.searched) {
      //new searched state
      let orderGroup = searchedOrder.list.find((orderItem) => {
        return orderItem.id === orderGroupId;
      });

      let newItemsSearched = orderGroup.orderedItems.map((eachItem) =>
        eachItem.id === itemId
          ? {
            ...eachItem,
            is_cooking: parseInt(eachItem.is_cooking) === 0 ? 1 : 0,
          }
          : eachItem
      );

      let newStateSearched = searchedOrder.list.map((orderItem) =>
        orderItem.id === orderGroupId
          ? {
            ...orderItem,
            is_accepted_by_kitchen: 1,
            orderedItems: newItemsSearched,
          }
          : orderItem
      );
      setSearchedOrder({
        ...searchedOrder,
        list: newStateSearched,
      });
    }

    //set server's item status
    const url = BASE_URL + "/settings/mark-order-item-ready-online";
    let formData = {
      orderGroupId: orderGroupId,
      id: itemId,
    };
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => { })
      .catch(() => {
        //undo if any error occured
        setKithcenNewOrdersOnline(oldState);
        setSearchedOrder({
          ...searchedOrder,
          list: oldSearchedState,
        });
        toast.error(`${_t(t("Please refresh and try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  
  return (
    <>
      <Helmet>
        <title>{_t(t("Online Order's Kitchen"))}</title>
      </Helmet>
       
       
       {/* Settle modal */}
      <div className="modal fade" id="orderDetails" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {/* show order token on modal header */}
                  {_t(t("Order details, Token"))}: #
                  {checkOrderDetails.item && checkOrderDetails.item.token}
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* if loading true show loading effect */}
            {loading ? (
              <div className="modal-body">{modalLoading(5)}</div>
            ) : (
              <div className="modal-body">
                {checkOrderDetails.item && (
                  // if this item is not settled then show settle-cancel button
                  <>
                    {checkOrderDetails.item &&
                      parseInt(checkOrderDetails.item.is_cancelled) !== 1 ? (
                      <div className="text-right">
                        {!checkOrderDetails.settle &&
                          !checkOrderDetails.cancel &&
                          parseInt(checkOrderDetails.item.is_accepted) !==
                          1 && (
                            <button
                              className="btn btn-primary px-3 rounded-md text-uppercase mr-2"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  cancel: true,
                                });
                              }}
                            >
                              {_t(t("Cancel"))}
                            </button>
                          )}
                        {!checkOrderDetails.settle && checkOrderDetails.cancel && (
                          <button
                            className="btn btn-outline-primary px-3 rounded-md text-uppercase mr-2"
                            onClick={() => {
                              setCheckOrderDetails({
                                ...checkOrderDetails,
                                cancel: false,
                                reason_of_cancel: null,
                              });
                            }}
                          >
                            {_t(t("Back"))}
                          </button>
                        )}
                        {checkOrderDetails.settle ? (
                          <button
                            className="btn btn-outline-primary px-3 rounded-md text-uppercase"
                            onClick={() => {
                              setCheckOrderDetails({
                                ...checkOrderDetails,
                                settle: false,
                                time_to_deliver: null,
                              });
                            }}
                          >
                            {_t(t("Back"))}
                          </button>
                        ) : (
                          <>
                            {parseInt(checkOrderDetails.item.is_accepted) !==
                              1 ? (
                              <>
                                {!checkOrderDetails.cancel && (
                                  <button
                                    className="btn btn-success px-3 rounded-md text-uppercase"
                                    onClick={() => {
                                      setCheckOrderDetails({
                                        ...checkOrderDetails,
                                        settle: true,
                                        time_to_deliver: null,
                                      });
                                    }}
                                  >
                                    {_t(t("Accept"))}
                                  </button>
                                )}
                              </>
                            ) : (
                              <div className="text-center bg-success text-white py-2">
                                {_t(t("Order has been accepted"))}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="text-center bg-secondary text-white py-2">
                        {_t(t("This order has been cancelled"))}
                      </div>
                    )}
                  </>
                )}

                {/* show this if order settle is not true, if true show payment input field */}
                {!checkOrderDetails.cancel ? (
                  <>
                    {!checkOrderDetails.settle ? (
                      <div className="col-12 filtr-item">
                        <div className="fk-order-token t-bg-white">
                          <div className="fk-order-token__body">
                            <div className="fk-addons-table">
                              <div className="fk-addons-table__head text-center">
                                {_t(t("order token"))}: #
                                {checkOrderDetails.item &&
                                  checkOrderDetails.item.token}
                              </div>
                              <div className="fk-addons-table__info">
                                <div className="row g-0">
                                  <div className="col-2 text-center border-right">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("S/L"))}
                                    </span>
                                  </div>
                                  <div className="col-3 text-center border-right">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("food"))}
                                    </span>
                                  </div>
                                  <div className="col-4 text-left pl-2 border-right">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("Additional Info"))}
                                    </span>
                                  </div>
                                  <div className="col-2 text-center border-right">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("QTY"))}
                                    </span>
                                  </div>
                                  <div className="col-1 text-center">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("Status"))}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {checkOrderDetails.item &&
                                checkOrderDetails.item.orderedItems.map(
                                  (thisItem, indexThisItem) => {
                                    return (
                                      <div className="fk-addons-table__body-row">
                                        <div className="row g-0">
                                          <div className="col-2 text-center border-right d-flex">
                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                              {indexThisItem + 1}
                                            </span>
                                          </div>
                                          <div className="col-3 text-center border-right d-flex">
                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                              {thisItem.food_item} (
                                              {thisItem.food_group})
                                            </span>
                                          </div>
                                          <div className="col-4 text-center border-right t-pl-10 t-pr-10">
                                            {thisItem.variation !== null && (
                                              <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                <span className="font-weight-bold mr-1">
                                                  {_t(t("variation"))}:
                                                </span>
                                                {thisItem.variation}
                                              </span>
                                            )}

                                            {thisItem.properties !== null && (
                                              <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                <span className="font-weight-bold mr-1">
                                                  {_t(t("properties"))}:
                                                </span>
                                                {JSON.parse(
                                                  thisItem.properties
                                                ).map(
                                                  (propertyItem, thisIndex) => {
                                                    if (
                                                      thisIndex !==
                                                      JSON.parse(
                                                        thisItem.properties
                                                      ).length -
                                                      1
                                                    ) {
                                                      return (
                                                        propertyItem.property +
                                                        `${propertyItem.quantity >
                                                          1
                                                          ? "(" +
                                                          propertyItem.quantity +
                                                          ")"
                                                          : ""
                                                        }` +
                                                        ", "
                                                      );
                                                    } else {
                                                      return (
                                                        propertyItem.property +
                                                        `${propertyItem.quantity >
                                                          1
                                                          ? "(" +
                                                          propertyItem.quantity +
                                                          ")"
                                                          : ""
                                                        }`
                                                      );
                                                    }
                                                  }
                                                )}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-2 text-center border-right d-flex">
                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                              {thisItem.quantity}
                                            </span>
                                          </div>

                                          <div className="col-1 text-center d-flex">
                                            <label className="mx-checkbox mx-checkbox--empty m-auto">
                                              <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                                {parseInt(
                                                  thisItem.is_cooking
                                                ) === 1 ? (
                                                  [
                                                    parseInt(
                                                      thisItem.is_ready
                                                    ) === 1 ? (
                                                      <i
                                                        className="fa fa-check text-success"
                                                        title={_t(t("Ready"))}
                                                      ></i>
                                                    ) : (
                                                      <i
                                                        className="fa fa-cutlery text-secondary"
                                                        title={_t(t("Cooking"))}
                                                      ></i>
                                                    ),
                                                  ]
                                                ) : (
                                                  <i
                                                    className="fa fa-times text-primary"
                                                    title={_t(t("Pending"))}
                                                  ></i>
                                                )}
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="my-2 payment-type-parent">
                        <form
                          className="border my-2 alert-success rounded-lg"
                          onSubmit={handleSubmit}
                        >
                          <div className="sm-text ml-2 py-2">
                            {_t(t("Time To Deliver The Order"))}
                          </div>
                          <div className="addons-list__item mx-2 mb-1">
                            <input
                              type="number"
                              min="0"
                              step="0.1"
                              name="time_to_deliver"
                              placeholder="Time in minutes"
                              className="form-control xsm-text pl-2"
                              onChange={(e) => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  time_to_deliver: e.target.value,
                                });
                              }}
                              value={checkOrderDetails.time_to_deliver}
                              required
                            />
                          </div>

                          <div className="sm-text ml-2 py-2">
                            {_t(t("Assign Delivery Man"))}
                          </div>
                          <div className="addons-list__item mx-2 mb-1">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  delivery_man_id: e.target.value,
                                });
                                // set deliverymen name
                                filterDeliveryName(e);
                              }}
                              required
                            >
                              <option value="">
                                {_t(t("Please select deliveryman"))}
                              </option>
                              {deliveryForSearch &&
                                deliveryForSearch.map((each) => {
                                  return (
                                    <option
                                      value={each.id}>
                                      {each.name}({each.phn_no})
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="pb-2 pl-2 mt-3 d-flex justify-content-center">
                            <button
                              className="btn btn-sm btn-success text-center px-3 text-uppercase"
                              type="submit"
                            >
                              {_t(t("Accept"))}
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="my-2 payment-type-parent">
                      <form
                        className="border my-2 alert-danger rounded-lg"
                        onSubmit={handleSubmitCancel}
                      >
                        <div className="sm-text text-center py-2">
                          {_t(t("Please write a reason"))}
                        </div>
                        <div className="addons-list__item mx-2 mb-1">
                          <input
                            type="text"
                            name="reason_of_cancel"
                            placeholder="Please write a reason"
                            className="form-control xsm-text pl-2"
                            onChange={(e) => {
                              setCheckOrderDetails({
                                ...checkOrderDetails,
                                reason_of_cancel: e.target.value,
                              });
                            }}
                            value={checkOrderDetails.reason_of_cancel}
                            required
                          />
                        </div>
                        <div className="pb-2 pl-2 my-2 d-flex justify-content-center">
                          <button
                            className="btn btn-sm btn-primary text-center text-dark px-3 text-uppercase"
                            type="submit"
                          >
                            {_t(t("Submit"))}
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                )}

                <table className="table table-striped table-sm text-center mt-3">
                  <thead className="bg-info text-white text-uppercase">
                    <tr>
                      <th scope="col" colSpan="2">
                        {_t(t("Order details"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-capitalized">{_t(t("Customer"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.user_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Contact"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.delivery_phn_no}
                      </td>
                    </tr>

                     <tr>
                      <td className="text-capitalized">{_t(t("Pick up date"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.pickupdate}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Pick up time"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.pickuptime}
                      </td>
                    </tr>


                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Delivery Address"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.delivery_address}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Note to rider"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.note_to_rider}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Payment Method"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && checkOrderDetails.item.payment_method}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Time To Deliver"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.time_to_deliver !== null
                          ? checkOrderDetails.item.time_to_deliver + " min(s)"
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Branch"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.branch_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Subtotal"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.order_bill)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    {checkOrderDetails.item &&
                      checkOrderDetails.item.vat_system === "igst" ? (
                      <tr>
                        <td className="text-capitalized">{_t(t("Igst"))}</td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.vat)}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tr>
                          <td className="text-capitalized">{_t(t("CGST"))}</td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  parseFloat(checkOrderDetails.item.cgst)
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-capitalized">{_t(t("SGST"))}</td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  parseFloat(checkOrderDetails.item.sgst)
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    )}

                    { /*<tr>
                      <td className="text-capitalized">
                        {_t(t("Delivery charge"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.theBranch.delivery_fee)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>
                    */ }

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Total bill"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.total_payable)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>
                    {checkOrderDetails.item &&
                      parseInt(checkOrderDetails.item.is_cancelled) === 1 && (
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Cancellation Reason"))}
                          </td>
                          <td>{checkOrderDetails.item.reason_of_cancel}</td>
                        </tr>
                      )}

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Delivery Man Status"))}
                      </td>
                      <td className="text-uppercase">
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.delivery_status !== null
                          ? checkOrderDetails.item.delivery_status
                          : _t(t("Not Assigned"))}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Transaction Id"))}
                      </td>
                      <td className="text-uppercase">
                      {checkOrderDetails.item && checkOrderDetails.item.payment_id}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Settle modal Ends*/}
       


      <main id="main" data-simplebar>
      
        <div className="fk-scroll--index t-mt-15 t-mb-15" data-simplebar>
          <div className="container-fluid">
            <div className="t-bg-white t-pt-10 t-pb-10 t-pl-15 t-pr-15">
              {/* next page data spin loading */}
              <div className={`${loading && "loading"}`}></div>
              {/* spin loading ends */}
              <div className="row gx-2 align-items-center">
                <div className="col-md-4 mt-md-4 mt-lg-0 t-mb-15 mb-md-0">
                  <ul className="t-list fk-breadcrumb">
                    <li className="fk-breadcrumb__list">
                      <span className="t-link fk-breadcrumb__link text-uppercase">
                        <span className="mr-2">
                          <img
                            src="/assets/img/cooking.png"
                            alt="cooking"
                            className="img-fluid"
                            style={{ height: "40px", width: "40px" }}
                          />
                        </span>
                        <span className="mr-1">
                          {_t(t("Online orders"))}
                        </span>{" "}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 mt-md-4 mt-lg-0">
                  <NavLink
                    to="/dashboard/kitchen"
                    onClick={() => {
                      getKitchenNewOrdersOnline();
                      setSearchedOrder({
                        ...searchedOrder,
                        searched: false,
                      });
                    }}
                    className="btn btn-warning btn-block sm-text text-uppercase mb-2 mb-md-0 text-truncate"
                  >
                    {_t(t("POS Orders"))}
                  </NavLink>
                </div>
                <div className="col-md-4 mt-md-4 mt-lg-0">
                  <button
                    type="button"
                    onClick={() => {
                      getKitchenNewOrdersOnline();
                      setSearchedOrder({
                        ...searchedOrder,
                        searched: false,
                      });
                    }}
                    className="btn btn-primary btn-block sm-text text-uppercase mb-2 mb-md-0 text-truncate"
                  >
                    {_t(t("Refresh"))}
                  </button>
                </div>
                <div className="col-md-4 mt-md-4 mt-lg-0">
                  <Select
                    options={filterOrder.groups && filterOrder.groups}
                    components={makeAnimated()}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    classNamePrefix="select"
                    className="xsm-text mb-2 mb-md-0 "
                    onChange={handleFilter}
                    maxMenuHeight="200px"
                    placeholder={_t(t("Filter by group")) + ".."}
                  />
                </div>
                {/* for branch wise */}
                {/* for branch wise */}
                {staffBranchId !== "staff" ?
                  <div className="col-md-4 mt-md-4 mt-lg-0">
                    <Select
                      options={filterBranchwise.branchGroups && filterBranchwise.branchGroups}
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      classNamePrefix="select"
                      className="xsm-text mb-2 mb-md-0 "
                      onChange={handleBranchFilter}
                      maxMenuHeight="200px"
                      placeholder={_t(t("Filter by branch")) + ".."}
                    />
                  </div>

                  : ''}

                <div className="col-md-4 mt-md-4 mt-lg-0">
                  <div className="input-group">
                    <div className="form-file">
                      <input
                        type="text"
                        className="form-control border-0 form-control--light-1 rounded-0"
                        placeholder={_t(t("Search by token")) + ".."}
                        onChange={handleSearch}
                      />
                    </div>
                    <button className="btn btn-primary" type="button">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {!searchedOrder.searched ? (
              <div className="row no-gutters g-4 mt-1">
                {kithcenNewOrdersOnline ? (
                  [
                    kithcenNewOrdersOnline.length > 0 ? (
                      kithcenNewOrdersOnline.map((item, index) => {

                        if (filterBranchwise.isFiltered) {
                          if (filterBranchwise.branchId === item.branch_id) {
                            return (
                              <div
                                className="col-md-6 col-xl-4"
                                data-category={index + 1}
                              >
                                
                                <div className="fk-order-token t-bg-white p-3 h-100">
                                  <div className="fk-order-token__footer text-right 1">
                                    <button
                                      type="button"
                                      className="btn btn-success xsm-text text-uppercase btn-lg mr-2"
                                      onClick={() =>
                                        handleReadyConfirmation(item.id)
                                      }
                                    >
                                      {_t(t("Order ready"))}
                                    </button>
                                    {parseInt(item.is_accepted) === 0 ? (
                                      <button
                                        type="button"
                                        className="btn btn-secondary xsm-text text-uppercase btn-lg"
                                        onClick={() =>
                                          handleAcceptOrReject(item.id)
                                        }
                                      >
                                        {_t(t("Accept order"))}
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-primary xsm-text text-uppercase btn-lg"
                                        onClick={() =>
                                          handleAcceptOrReject(item.id)
                                        }
                                      >
                                        {_t(t("Make order pending"))}
                                      </button>
                                    )}
                                  </div>
                                  <div className="fk-order-token__body">
                                    <div className="fk-addons-table">
                                      <div className="fk-addons-table__head d-flex justify-content-between px-3">
                                        <span>
                                          {_t(t("order token"))}: #{item.token}
                                        </span>
                                        <span>
                                          {_t(t("ordered at"))}:{" "}
                                          <Moment format="DD/MM/yy">
                                            {item.created_at}
                                          </Moment>
                                        </span>
                                      </div>
                                      <div className="fk-addons-table__info">
                                        <div className="row g-0">
                                          <div className="col-2 text-center border-right py-2">
                                            <span className="fk-addons-table__info-text text-capitalize">
                                              {_t(t("S/L"))}
                                            </span>
                                          </div>
                                          <div className="col-3 text-center border-right py-2">
                                            <span className="fk-addons-table__info-text text-capitalize">
                                              {_t(t("food"))}
                                            </span>
                                          </div>
                                          <div className="col-4 text-center border-right py-2">
                                            <span className="fk-addons-table__info-text text-capitalize">
                                              {_t(t("Additional Info"))}
                                            </span>
                                          </div>
                                          <div className="col-2 text-center border-right py-2">
                                            <span className="fk-addons-table__info-text text-capitalize">
                                              {_t(t("QTY"))}
                                            </span>
                                          </div>
                                          <div className="col-1 text-center py-2">
                                            <span className="fk-addons-table__info-text text-capitalize">
                                              <i className="fa fa-check"></i>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="fk-addons-table__body">
                                        {item.orderedItems.map(
                                          (thisItem, indexThisItem) => {
                                            if (filterOrder.isFiltered) {
                                              if (
                                                thisItem.food_group ===
                                                filterOrder.filterKey
                                              ) {
                                                return (
                                                  <div class="fk-addons-table__body-row">
                                                    <div class="row g-0">
                                                      <div class="col-2 text-center border-right d-flex py-2">
                                                        <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                          {indexThisItem + 1}
                                                        </span>
                                                      </div>
                                                      <div class="col-3 text-center border-right d-flex py-2">
                                                        <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                          {thisItem.food_item} (
                                                          {thisItem.food_group})
                                                        </span>
                                                      </div>
                                                      <div class="col-4 text-center border-right t-pl-10 t-pr-10 py-2">
                                                        {thisItem.variation !==
                                                          null && (
                                                            <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                              <span class="font-weight-bold mr-1">
                                                                {_t(t("variation"))}:
                                                              </span>
                                                              {thisItem.variation}
                                                            </span>
                                                          )}

                                                        {thisItem.properties !==
                                                          null && (
                                                            <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                              <span class="font-weight-bold mr-1">
                                                                {_t(t("properties"))}:
                                                              </span>
                                                              {JSON.parse(
                                                                thisItem.properties
                                                              ).map(
                                                                (
                                                                  propertyItem,
                                                                  thisIndex
                                                                ) => {
                                                                  if (
                                                                    thisIndex !==
                                                                    JSON.parse(
                                                                      thisItem.properties
                                                                    ).length -
                                                                    1
                                                                  ) {
                                                                    return (
                                                                      propertyItem.property +
                                                                      ", "
                                                                    );
                                                                  } else {
                                                                    return propertyItem.property;
                                                                  }
                                                                }
                                                              )}
                                                            </span>
                                                          )}
                                                      </div>
                                                      <div class="col-2 text-center border-right d-flex py-2">
                                                        <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                          {thisItem.quantity}
                                                        </span>
                                                      </div>

                                                      <div className="col-1 text-center d-flex py-2">
                                                        <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                          <input
                                                            type="checkbox"
                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                            checked={
                                                              parseInt(
                                                                thisItem.is_cooking
                                                              ) === 1
                                                            }
                                                            onChange={() => {
                                                              handleEachItemReady(
                                                                item.id,
                                                                thisItem.id
                                                              );
                                                            }}
                                                          />
                                                          <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            } else {
                                              return (
                                                <div class="fk-addons-table__body-row">
                                                  <div class="row g-0">
                                                    <div class="col-2 text-center border-right d-flex py-2">
                                                      <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                        {indexThisItem + 1}
                                                      </span>
                                                    </div>
                                                    <div class="col-3 text-center border-right d-flex py-2">
                                                      <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                        {thisItem.food_item} (
                                                        {thisItem.food_group})
                                                      </span>
                                                    </div>
                                                    <div class="col-4 text-center border-right t-pl-10 t-pr-10 py-2">
                                                      {thisItem.variation !==
                                                        null && (
                                                          <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                            <span class="font-weight-bold mr-1">
                                                              {_t(t("variation"))}:
                                                            </span>
                                                            {thisItem.variation}
                                                          </span>
                                                        )}

                                                      {thisItem.properties !==
                                                        null && (
                                                          <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                            <span class="font-weight-bold mr-1">
                                                              {_t(t("properties"))}:
                                                            </span>
                                                            {JSON.parse(
                                                              thisItem.properties
                                                            ).map(
                                                              (
                                                                propertyItem,
                                                                thisIndex
                                                              ) => {
                                                                if (
                                                                  thisIndex !==
                                                                  JSON.parse(
                                                                    thisItem.properties
                                                                  ).length -
                                                                  1
                                                                ) {
                                                                  return (
                                                                    propertyItem.property +
                                                                    ", "
                                                                  );
                                                                } else {
                                                                  return propertyItem.property;
                                                                }
                                                              }
                                                            )}
                                                          </span>
                                                        )}
                                                    </div>
                                                    <div class="col-2 text-center border-right d-flex py-2">
                                                      <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                        {thisItem.quantity}
                                                      </span>
                                                    </div>

                                                    <div className="col-1 text-center d-flex py-2">
                                                      <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                        <input
                                                          type="checkbox"
                                                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                          checked={
                                                            parseInt(
                                                              thisItem.is_cooking
                                                            ) === 1
                                                          }
                                                          onChange={() => {
                                                            handleEachItemReady(
                                                              item.id,
                                                              thisItem.id
                                                            );
                                                          }}
                                                        />
                                                        <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        } else {
                          return (
                            <div
                              className="col-md-6 col-xl-4 position-relative "
                              data-category={index + 1}
                            >
                            {(() => {
                              if (item.is_accepted==0) {
                                return (
                              <div className="gcspapprovebuttonwaper">
                                  <button type="button" className="btn btn-success xsm-text text-uppercase btn-lg"
                                        onClick={() => {
                                            setCheckOrderDetails({
                                              ...checkOrderDetails,
                                              item: item,
                                              settle: false,
                                              cancel: false,
                                            });
                                          }}
                                          data-toggle="modal"
                                          data-target="#orderDetails"
                                      >
                                        {_t(t("Accept"))}
                                      </button>
                              </div>
                                )
                              }
                            })()}  
                              <div className="fk-order-token t-bg-white p-3 h-100">
                              
                                <div className="fk-order-token__footer text-right 2">
                                  <button
                                    type="button"
                                    className="btn btn-success xsm-text text-uppercase btn-lg mr-2"
                                    onClick={() =>
                                      handleReadyConfirmation(item.id)
                                    }
                                  >
                                    {_t(t("Order ready"))}
                                  </button>
                                  {parseInt(item.is_accepted) === 0 ? (
                                    <button
                                      type="button"
                                      className="btn btn-secondary xsm-text text-uppercase btn-lg"
                                      onClick={() =>
                                        handleAcceptOrReject(item.id)
                                      }
                                    >
                                      {_t(t("Accept order"))}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-primary xsm-text text-uppercase btn-lg"
                                      onClick={() =>
                                        handleAcceptOrReject(item.id)
                                      }
                                    >
                                      {_t(t("Make order pending"))}
                                    </button>
                                  )}
                                </div>
                                <div className="fk-order-token__body">
                                  <div className="fk-addons-table">
                                    <div className="fk-addons-table__head d-flex justify-content-between px-3">
                                      <span>
                                        {_t(t("order token"))}: #{item.token}
                                      </span>
                                      <span>
                                        {_t(t("ordered at"))}:{" "}
                                        <Moment format="DD/MM/yy">
                                          {item.created_at}
                                        </Moment>
                                      </span>
                                    </div>
                                    <div className="fk-addons-table__info">
                                      <div className="row g-0">
                                        <div className="col-2 text-center border-right py-2">
                                          <span className="fk-addons-table__info-text text-capitalize">
                                            {_t(t("S/L"))}
                                          </span>
                                        </div>
                                        <div className="col-3 text-center border-right py-2">
                                          <span className="fk-addons-table__info-text text-capitalize">
                                            {_t(t("food"))}
                                          </span>
                                        </div>
                                        <div className="col-4 text-center border-right py-2">
                                          <span className="fk-addons-table__info-text text-capitalize">
                                            {_t(t("Additional Info"))}
                                          </span>
                                        </div>
                                        <div className="col-2 text-center border-right py-2">
                                          <span className="fk-addons-table__info-text text-capitalize">
                                            {_t(t("QTY"))}
                                          </span>
                                        </div>
                                        <div className="col-1 text-center py-2">
                                          <span className="fk-addons-table__info-text text-capitalize">
                                            <i className="fa fa-check"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="fk-addons-table__body">
                                      {item.orderedItems.map(
                                        (thisItem, indexThisItem) => {
                                          if (filterOrder.isFiltered) {
                                            if (
                                              thisItem.food_group ===
                                              filterOrder.filterKey
                                            ) {
                                              return (
                                                <div class="fk-addons-table__body-row">
                                                  <div class="row g-0">
                                                    <div class="col-2 text-center border-right d-flex py-2">
                                                      <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                        {indexThisItem + 1}
                                                      </span>
                                                    </div>
                                                    <div class="col-3 text-center border-right d-flex py-2">
                                                      <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                        {thisItem.food_item} (
                                                        {thisItem.food_group})
                                                      </span>
                                                    </div>
                                                    <div class="col-4 text-center border-right t-pl-10 t-pr-10 py-2">
                                                      {thisItem.variation !==
                                                        null && (
                                                          <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                            <span class="font-weight-bold mr-1">
                                                              {_t(t("variation"))}:
                                                            </span>
                                                            {thisItem.variation}
                                                          </span>
                                                        )}

                                                      {thisItem.properties !==
                                                        null && (
                                                          <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                            <span class="font-weight-bold mr-1">
                                                              {_t(t("properties"))}:
                                                            </span>
                                                            {JSON.parse(
                                                              thisItem.properties
                                                            ).map(
                                                              (
                                                                propertyItem,
                                                                thisIndex
                                                              ) => {
                                                                if (
                                                                  thisIndex !==
                                                                  JSON.parse(
                                                                    thisItem.properties
                                                                  ).length -
                                                                  1
                                                                ) {
                                                                  return (
                                                                    propertyItem.property +
                                                                    ", "
                                                                  );
                                                                } else {
                                                                  return propertyItem.property;
                                                                }
                                                              }
                                                            )}
                                                          </span>
                                                        )}
                                                    </div>
                                                    <div class="col-2 text-center border-right d-flex py-2">
                                                      <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                        {thisItem.quantity}
                                                      </span>
                                                    </div>

                                                    <div className="col-1 text-center d-flex py-2">
                                                      <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                        <input
                                                          type="checkbox"
                                                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                          checked={
                                                            parseInt(
                                                              thisItem.is_cooking
                                                            ) === 1
                                                          }
                                                          onChange={() => {
                                                            handleEachItemReady(
                                                              item.id,
                                                              thisItem.id
                                                            );
                                                          }}
                                                        />
                                                        <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          } else {
                                            return (
                                              <div class="fk-addons-table__body-row">
                                                <div class="row g-0">
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {indexThisItem + 1}
                                                    </span>
                                                  </div>
                                                  <div class="col-3 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.food_item} (
                                                      {thisItem.food_group})
                                                    </span>
                                                  </div>
                                                  <div class="col-4 text-center border-right t-pl-10 t-pr-10 py-2">
                                                    {thisItem.variation !==
                                                      null && (
                                                        <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                          <span class="font-weight-bold mr-1">
                                                            {_t(t("variation"))}:
                                                          </span>
                                                          {thisItem.variation}
                                                        </span>
                                                      )}

                                                    {thisItem.properties !==
                                                      null && (
                                                        <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                          <span class="font-weight-bold mr-1">
                                                            {_t(t("properties"))}:
                                                          </span>
                                                          {JSON.parse(
                                                            thisItem.properties
                                                          ).map(
                                                            (
                                                              propertyItem,
                                                              thisIndex
                                                            ) => {
                                                              if (
                                                                thisIndex !==
                                                                JSON.parse(
                                                                  thisItem.properties
                                                                ).length -
                                                                1
                                                              ) {
                                                                return (
                                                                  propertyItem.property +
                                                                  ", "
                                                                );
                                                              } else {
                                                                return propertyItem.property;
                                                              }
                                                            }
                                                          )}
                                                        </span>
                                                      )}
                                                  </div>
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.quantity}
                                                    </span>
                                                  </div>

                                                  <div className="col-1 text-center d-flex py-2">
                                                    <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                      <input
                                                        type="checkbox"
                                                        className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                        checked={
                                                          parseInt(
                                                            thisItem.is_cooking
                                                          ) === 1
                                                        }
                                                        onChange={() => {
                                                          handleEachItemReady(
                                                            item.id,
                                                            thisItem.id
                                                          );
                                                        }}
                                                      />
                                                      <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }



                      })
                    ) : (
                      <div className="col-8 offset-2" data-category="1">
                        <div className="fk-order-token t-bg-white p-5 text-center text-uppercase text-primary no-order">
                          {/* No order in the kitchen */}
                          <img
                            src="/assets/img/no-order.jpg"
                            alt="no order found"
                            className="img-fluid h-100"
                          />
                        </div>
                      </div>
                    ),
                  ]
                ) : (
                  <div className="col-12" data-category="1">
                    <Skeleton
                      className="fk-order-token t-bg-white p-3 border border-2"
                      style={{ minHeight: "560px" }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="row no-gutters g-4 mt-1">
                {searchedOrder ? (
                  [
                    searchedOrder.list.length > 0 ? (
                      searchedOrder.list.map((item, index) => {
                        return (
                          <div
                            className="col-md-6 col-xl-4"
                            data-category={index + 1}
                          >
                            <div className="fk-order-token t-bg-white p-3 h-100">
                              <div className="fk-order-token__footer text-right 3">
                                <button
                                  type="button"
                                  className="btn btn-success xsm-text text-uppercase btn-lg mr-2"
                                  onClick={() =>
                                    handleReadyConfirmation(item.id)
                                  }
                                >
                                  {_t(t("Order ready"))}
                                </button>
                                {parseInt(item.is_accepted_by_kitchen) === 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn-secondary xsm-text text-uppercase btn-lg"
                                    onClick={() =>
                                      handleAcceptOrReject(item.id)
                                    }
                                  >
                                    {_t(t("Accept order"))}
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary xsm-text text-uppercase btn-lg"
                                    onClick={() =>
                                      handleAcceptOrReject(item.id)
                                    }
                                  >
                                    {_t(t("Make order pending"))}
                                  </button>
                                )}
                              </div>
                              <div className="fk-order-token__body">
                                <div className="fk-addons-table">
                                  <div className="fk-addons-table__head d-flex justify-content-between px-3">
                                    <span>
                                      {_t(t("order token"))}: #{item.token}
                                    </span>
                                    <span>
                                      {_t(t("ordered at"))}:{" "}
                                      <Moment format="DD/MM/yy">
                                        {item.created_at}
                                      </Moment>
                                    </span>
                                  </div>
                                  <div className="fk-addons-table__info">
                                    <div className="row g-0">
                                      <div className="col-2 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("S/L"))}
                                        </span>
                                      </div>
                                      <div className="col-3 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("food"))}
                                        </span>
                                      </div>
                                      <div className="col-4 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("Additional Info"))}
                                        </span>
                                      </div>
                                      <div className="col-2 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("QTY"))}
                                        </span>
                                      </div>
                                      <div className="col-1 text-center py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          <i className="fa fa-check"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fk-addons-table__body">
                                    {item.orderedItems.map(
                                      (thisItem, indexThisItem) => {
                                        if (filterOrder.isFiltered) {
                                          if (
                                            thisItem.food_group ===
                                            filterOrder.filterKey
                                          ) {
                                            return (
                                              <div class="fk-addons-table__body-row">
                                                <div class="row g-0">
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {indexThisItem + 1}
                                                    </span>
                                                  </div>
                                                  <div class="col-3 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.food_item} (
                                                      {thisItem.food_group})
                                                    </span>
                                                  </div>
                                                  <div class="col-4 text-center border-right t-pl-10 t-pr-10 py-2">
                                                    {thisItem.variation !==
                                                      null && (
                                                        <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                          <span class="font-weight-bold mr-1">
                                                            {_t(t("variation"))}:
                                                          </span>
                                                          {thisItem.variation}
                                                        </span>
                                                      )}

                                                    {thisItem.properties !==
                                                      null && (
                                                        <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                          <span class="font-weight-bold mr-1">
                                                            {_t(t("properties"))}:
                                                          </span>
                                                          {JSON.parse(
                                                            thisItem.properties
                                                          ).map(
                                                            (
                                                              propertyItem,
                                                              thisIndex
                                                            ) => {
                                                              if (
                                                                thisIndex !==
                                                                JSON.parse(
                                                                  thisItem.properties
                                                                ).length -
                                                                1
                                                              ) {
                                                                return (
                                                                  propertyItem.property +
                                                                  ", "
                                                                );
                                                              } else {
                                                                return propertyItem.property;
                                                              }
                                                            }
                                                          )}
                                                        </span>
                                                      )}
                                                  </div>
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.quantity}
                                                    </span>
                                                  </div>

                                                  <div className="col-1 text-center d-flex py-2">
                                                    <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                      <input
                                                        type="checkbox"
                                                        className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                        checked={
                                                          parseInt(
                                                            thisItem.is_cooking
                                                          ) === 1
                                                        }
                                                        onChange={() => {
                                                          handleEachItemReady(
                                                            item.id,
                                                            thisItem.id
                                                          );
                                                        }}
                                                      />
                                                      <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        } else {
                                          return (
                                            <div class="fk-addons-table__body-row">
                                              <div class="row g-0">
                                                <div class="col-2 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {indexThisItem + 1}
                                                  </span>
                                                </div>
                                                <div class="col-3 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.food_item} (
                                                    {thisItem.food_group})
                                                  </span>
                                                </div>
                                                <div class="col-4 text-center border-right t-pl-10 t-pr-10 py-2">
                                                  {thisItem.variation !==
                                                    null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("variation"))}:
                                                        </span>
                                                        {thisItem.variation}
                                                      </span>
                                                    )}

                                                  {thisItem.properties !==
                                                    null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("properties"))}:
                                                        </span>
                                                        {JSON.parse(
                                                          thisItem.properties
                                                        ).map(
                                                          (
                                                            propertyItem,
                                                            thisIndex
                                                          ) => {
                                                            if (
                                                              thisIndex !==
                                                              JSON.parse(
                                                                thisItem.properties
                                                              ).length -
                                                              1
                                                            ) {
                                                              return (
                                                                propertyItem.property +
                                                                ", "
                                                              );
                                                            } else {
                                                              return propertyItem.property;
                                                            }
                                                          }
                                                        )}
                                                      </span>
                                                    )}
                                                </div>
                                                <div class="col-2 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.quantity}
                                                  </span>
                                                </div>

                                                <div className="col-1 text-center d-flex py-2">
                                                  <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                    <input
                                                      type="checkbox"
                                                      className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                                      checked={
                                                        parseInt(
                                                          thisItem.is_cooking
                                                        ) === 1
                                                      }
                                                      onChange={() => {
                                                        handleEachItemReady(
                                                          item.id,
                                                          thisItem.id
                                                        );
                                                      }}
                                                    />
                                                    <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text"></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-8 offset-2" data-category="1">
                        <div className="fk-order-token t-bg-white p-5 text-center text-uppercase text-primary no-order">
                          {/* No order in the kitchen */}
                          <img
                            src="/assets/img/no-order.jpg"
                            alt="no order found"
                            className="img-fluid h-100"
                          />
                        </div>
                      </div>
                    ),
                  ]
                ) : (
                  <div className="col-12" data-category="1">
                    <Skeleton
                      className="fk-order-token t-bg-white p-3 border border-2"
                      style={{ minHeight: "560px" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default KitchenOnline;
