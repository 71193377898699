import React from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";

//3rd party packages
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

//functions
import { _t } from "../../functions/Functions";
import { useTranslation } from "react-i18next";
const NoPermission = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{_t(t("No permission"))}</title>
      </Helmet>
      <main class="fk-page-404">
        <div class="fk-page-404__img mt-auto">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <img src="/assets/img/noacess.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div class="fk-page-404__return-home t-mt-30 mb-auto">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <NavLink
                  to="/dashboard"
                  class="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-primary xsm-text text-uppercase text-center"
                >
                  {_t(t("Go to dashboard"))}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default NoPermission;
