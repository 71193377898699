import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    getSystemSettings,
    tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// settings context
import { SettingsContext } from "../../../../contexts/Settings";

//pages & includes
import Templatesidebar from "./Templatesidebar";


const Nouscontact = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //getting context values here
    let { templateOneSettings, loading,generalSettings } =
        useContext(SettingsContext);

    // state starts here
    const [contactContent, setContactContent] = useState({
        contacttitle: getSystemSettings(generalSettings, "contacttitle"),        
        contactcontent: getSystemSettings(generalSettings, "contactcontent"),        
        contacttime: getSystemSettings(generalSettings, "contacttime"),        
        contactaddress: getSystemSettings(generalSettings, "contactaddress"),        
        contactfacilityone: getSystemSettings(generalSettings, "contactfacilityone"),        
        contactfacilitytwo: getSystemSettings(generalSettings, "contactfacilitytwo"),        
        contactadminemail: getSystemSettings(generalSettings, "contactadminemail"),    
        contactmap: getSystemSettings(generalSettings, "contactmap"),    
        
    });
    const [picture, setPicture] = useState([]);

    // onchange function
    const handleOnChange = (e) => {
        e.persist();
        setContactContent({
            ...contactContent,
            [e.target.name]: e.target.value
        })
    }

    // use effect
    useEffect(() => {
        const url = BASE_URL + `/settings/general-settings`;
        axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {        
            setContactContent({
                contacttitle: getSystemSettings(res.data, "contacttitle"),        
                contactcontent: getSystemSettings(res.data, "contactcontent"),        
                contacttime: getSystemSettings(res.data, "contacttime"),        
                contactaddress: getSystemSettings(res.data, "contactaddress"),        
                contactfacilityone: getSystemSettings(res.data, "contactfacilityone"),        
                contactfacilitytwo: getSystemSettings(res.data, "contactfacilitytwo"),        
                contactadminemail: getSystemSettings(res.data, "contactadminemail"),  
                contactmap: getSystemSettings(res.data, "contactmap"),                
            })
            
        });
    }, []);

    // handle image
    const handleImage = (e) => {
        setPicture({ image: e.target.files[0] });
    };

    // handle submit
    const handlesubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(); 
        
        formData.append("contacttitle", contactContent.contacttitle);
        formData.append("contactcontent", contactContent.contactcontent);
        formData.append("contacttime", contactContent.contacttime);
        formData.append("contactaddress", contactContent.contactaddress);
        formData.append("contactfacilityone", contactContent.contactfacilityone);
        formData.append("contactfacilitytwo", contactContent.contactfacilitytwo);
        formData.append("contactadminemail", contactContent.contactadminemail);
        formData.append("contactmap", contactContent.contactmap);        
        formData.append("contactimage", picture.image);       
        
        const url = BASE_URL + "/settings/store-contact-section";
        axios.post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            if (res.data.status == 200) {
                toast.success(`${_t(t("updated successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            } else if (res.data.status == 422) {
                toast.error(`${_t(t("Try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            }
        });
    };

    return (
      <>
        <Helmet>
          <title>{_t(t("Contact us"))}</title>
        </Helmet>

        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                <Templatesidebar />
              </div>
              {/* left Sidebar ends */}

              {/* Rightbar contents */}
              <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0 ">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide t-pt-50"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      <form
                        onSubmit={handlesubmit}
                        className="row card p-2 mx-3 mb-5 sm-text"
                      >
                        <div className="col-12">
                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label for="name" className="control-label">
                                {_t(t("Title"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                onChange={handleOnChange}
                                className="form-control"
                                name="contacttitle"
                                placeholder=""
                                required=""
                                value={contactContent.contacttitle}
                              />
                            </div>
                          </div>
                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label for="name" className="control-label">
                                {_t(t("Content"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <textarea
                                onChange={handleOnChange}
                                className="form-control"
                                name="contactcontent"
                                placeholder=""
                                required=""
                                value={contactContent.contactcontent}
                              />
                            </div>
                          </div>

                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label for="name" className="control-label">
                                {_t(t("Time"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                onChange={handleOnChange}
                                className="form-control"
                                name="contacttime"
                                placeholder=""
                                required=""
                                value={contactContent.contacttime}
                              />
                            </div>
                          </div>
                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label for="name" className="control-label">
                                {_t(t("Address"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                onChange={handleOnChange}
                                className="form-control"
                                name="contactaddress"
                                placeholder=""
                                required=""
                                value={contactContent.contactaddress}
                              />
                            </div>
                          </div>
                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label for="name" className="control-label">
                                {_t(t("Facility one"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                onChange={handleOnChange}
                                className="form-control"
                                name="contactfacilityone"
                                placeholder=""
                                required=""
                                value={contactContent.contactfacilityone}
                              />
                            </div>
                          </div>
                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label for="name" className="control-label">
                                {_t(t("Facility two"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                onChange={handleOnChange}
                                className="form-control"
                                name="contactfacilitytwo"
                                placeholder=""
                                required=""
                                value={contactContent.contactfacilitytwo}
                              />
                            </div>
                          </div>
                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label for="name" className="control-label">
                                {_t(
                                  t("Contact form notification receiver email")
                                )}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="email"
                                onChange={handleOnChange}
                                className="form-control"
                                name="contactadminemail"
                                placeholder=""
                                required=""
                                value={contactContent.contactadminemail}
                              />
                            </div>
                          </div>

                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label for="name" className="control-label">
                                {_t(t("Map"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <textarea
                                onChange={handleOnChange}
                                className="form-control"
                                name="contactmap"
                                placeholder=""
                                required=""
                                value={contactContent.contactmap}
                              />
                            </div>
                          </div>

                          <div className="form-group mt-4">
                            <div className="mb-2">
                              <label for="image" className="control-label">
                                Image<span className="text-danger">*</span>{" "}
                                <small className="text-secondary">
                                  (300 x 300 Preferable)
                                </small>
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                name="image"
                                type="file"
                                className="form-control"
                                onChange={handleImage}
                              />
                            </div>
                          </div>

                          <div className="form-group mt-5 pb-2">
                            <div className="col-lg-12">
                              <button
                                className="btn btn-primary px-5"
                                type="submit"
                              >
                                {_t(t("Save"))}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
};

export default Nouscontact;
