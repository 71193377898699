import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

//importing context consumer here
import { UserContext } from "../../../contexts/User";
import { FoodContext } from "../../../contexts/Food";
import { RestaurantContext } from "../../../contexts/Restaurant";
import { SettingsContext } from "../../../contexts/Settings";

import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";

//functions
import {
  _t,
  modalLoading,
  restaurantMenuLink,
  getSystemSettings,
  getCookie,
  deleteCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  setCardDetails,
  getCardDetails,
} from "../../../functions/Functions";

//3rd party packages
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//jQuery initialization
import $ from "jquery";

const RestaurantLanding = () => {
  registerLocale("fr", fr);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [PickupDate, setPickupDate] = useState(null);
  const [PickupTime, setPickupTime] = useState(null);

  //getting context values here
  let {
    navLanguageList,
    navCurrencyList,
    generalSettings,
    showManageStock,
    paypal_client_id,
  } = useContext(SettingsContext);
  //auth user
  const { authUserInfo } = useContext(UserContext);
  //restaurant
  let { branchForSearch } = useContext(RestaurantContext);
  //food
  let {
    getFoodWeb,
    foodListWeb,
    foodGroupWeb,
    propertyGroupWeb,
    workPeriodWeb,
    foodStockWeb,
    setFoodStockWeb,
  } = useContext(FoodContext);

  //use state
  const [loading, setLoading] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showVariation, setShowVariation] = useState(false);

  // stripe
  const [showStripe, setshowStripe] = useState("");

  // branch delivery charge
  const [branchDeliveryCharge, setBranchDeliveryCharge] = useState(0);

  const [deliverymenShow, setdeliverymenShow] = useState(false);
  const [checkLoginUser, setCheckLoginUser] = useState(0);
  //
  const [defaultLang, setDefaultLang] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(null);

  //food and group
  const [foodItems, setFoodItems] = useState({
    list: null, //default on render
    group: null, //default on render
    selectedItem: null, //on select
    variations: null,
    properties: null,
  });

  //new order
  const [newOrder, setNewOrder] = useState({
    variation: null,
    quantity: 1,
    properties: null,
  });

  //order details

  const [orderDetails, setOrderDetails] = useState({
    items: [],
    branch: 11, //on select branch
    workPeriod: null, // on select branch
    workPeriodStatus: false,
    address: "null", //null get on render user info
    name: null, //get on render
    phn_no: null, //get on render
    note: null,
    payment_type: "COD",
    pickupdate: PickupDate,
    pickuptime: PickupTime,
    uploading: false,
  });

  // check auth user
  const checkLoginfunc = () => {
    getCookie() !== undefined ? setCheckLoginUser(0) : setCheckLoginUser(1);
  };
  // show stripe
  const showstripebtn = (e) => {
    setLoading(true);
    const url = BASE_URL + `/settings/show-stripe-btn`;
    return axios.get(url).then((res) => {
      setshowStripe(res.data);
      setLoading(false);
    });
  };

  const setShowPaymentForm = (total, orderDetails, taxamount) => {
    const url = BASE_URL + `/website/paymentform`;
    var datevalue = $(
      ".kh-drawer .react-datepicker__input-container input"
    ).val();
    if (datevalue == "") {
      toast.error(`${_t(t("Recovery date is required"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });

      return false;
    }
    var timevalue = $(
      '.kh-drawer .react-time-picker__wrapper input[name="time"]'
    ).val();
    if (timevalue == "") {
      toast.error(`${_t(t("Recovery time is required"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false;
    }
    if (timevalue != "" && datevalue != "") {
      return axios
        .post(
          url,
          {
            total: total,
            taxamount: taxamount,
            orderDetails: orderDetails,
            authUserInfo: authUserInfo.details,
          },
          {
            headers: { Authorization: `Bearer ${getCookie()}` },
          }
        )
        .then((res) => {
          $(".checkoutform").html(res.data);
        });
    }
  };

  //useeffect == componentDidMount()
  useEffect(() => {
    handleJquery();
    getFoodWeb();
    deliveryMenu();
    handleOnLoadDefaultLang();
    handleOnLoadDefaultCurrency();
    checkLoginfunc();
    showstripebtn();
    setOrderDetails({
      ...orderDetails,
      pickupdate: PickupDate,
      pickuptime: PickupTime,
      address: authUserInfo.details && authUserInfo.details.address,
      name: authUserInfo.details && authUserInfo.details.name,
      phn_no: authUserInfo.details && authUserInfo.details.phn_no,
    });

    if (foodGroupWeb && foodListWeb) {
      let temp = foodListWeb.filter((foodItem, foodItemIndex) => {
        return parseInt(foodItem.food_group_id) === foodGroupWeb[0].id;
      });
      setFoodItems({ ...foodItems, list: temp, group: foodGroupWeb[0] });
    }
    setInterval(() => {
      setLoading(false);
    }, 4000);
    setTimeout(() => {
      if (getCardDetails()) {
        var itemsdata = JSON.parse(getCardDetails());
        let oldCartItems = [];
        itemsdata.items.map((item) => {
          console.log(item);
          oldCartItems.push({
            item: item.item,
            properties: item.properties,
            quantity: item.quantity,
            subTotal: item.subTotal,
          });
        });

        setOrderDetails({
          ...orderDetails,
          items: oldCartItems,
          pickupdate: PickupDate,
          pickuptime: PickupTime,
          address: authUserInfo.details && authUserInfo.details.address,
          name: authUserInfo.details && authUserInfo.details.name,
          phn_no: authUserInfo.details && authUserInfo.details.phn_no,
        });
      }
    }, 1500);
  }, [authUserInfo, navCurrencyList]);

  // deliveryman menu update
  const deliveryMenu = () => {
    const url = BASE_URL + `/settings/deliverymen-menu-info`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data.length == 0 || res.data[0].value == 1) {
          setdeliverymenShow(true);
        } else {
          setdeliverymenShow(false);
        }
      });
  };

  //handle jQuery
  const handleJquery = () => {
    $(window).on("scroll", function () {
      var toTopVisible = $("html").scrollTop();
      if (toTopVisible > 500) {
        $(".scrollup").fadeIn();
      } else {
        $(".scrollup").fadeOut();
      }
    });

    // MouseHover Animation home 1
    var hoverLayer = $(".banner-area");
    var heroImgOne = $(".p-shape-1");
    var heroImgTwo = $(".p-shape-2");
    var heroImgThree = $(".p-shape-3");
    var heroImgFour = $(".p-shape-4");
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgOne.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgTwo.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgThree.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgFour.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    // MouseHover Animation home 2
    var hoverLayer2 = $(".burger-promo-area");
    var heroImgfive = $(".bs1");
    var heroImgsix = $(".bs2");
    var heroImgseven = $(".bs5");
    var heroImgeight = $(".bs6");
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgfive.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgsix.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgseven.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgeight.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    // MouseHover Animation home 3
    var hoverLayer3 = $(".snack-section");
    var heroImgnine = $(".ss1");
    var heroImgten = $(".ss2");
    var heroImgeleven = $(".ss3");
    var heroImgtweleve = $(".mss2");
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgtweleve.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgnine.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgten.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgeleven.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
  };

  //dynamic style
  const style = {
    logo: {
      backgroundImage:
        generalSettings &&
        `url(${getSystemSettings(generalSettings, "type_logo")})`,
    },
    currency: {
      backgroundColor:
        generalSettings && getSystemSettings(generalSettings, "type_clock"),
      color:
        generalSettings && getSystemSettings(generalSettings, "type_color"),
    },
  };

  //logout
  const handleLogout = () => {
    deleteCookie();
  };

  //orders variation
  const handleOrderItemVariation = (item) => {
    setNewOrder({
      ...newOrder,
      variation: item,
    });
  };

  //property
  const handleOrderItemProperty = (propertyItem) => {
    let newTemp = [];
    if (newOrder.properties !== null && newOrder.properties.length > 0) {
      let checkExist = newOrder.properties.find((exist) => {
        return exist.id === propertyItem.id;
      });
      if (checkExist === undefined) {
        newOrder.properties.map((oldItem) => {
          newTemp.push(oldItem);
        });
        propertyItem.quantity = 1;
        newTemp.push(propertyItem);
      } else {
        newOrder.properties.map((oldItem) => {
          if (oldItem.id !== propertyItem.id) {
            newTemp.push(oldItem);
          }
        });
      }
      setNewOrder({
        ...newOrder,
        properties: newTemp,
      });
    } else {
      propertyItem.quantity = 1;
      setNewOrder({
        ...newOrder,
        properties: [propertyItem],
      });
    }
  };

  //multiple qty
  const checkedProperty = (eachItem) => {
    if (newOrder.properties !== null) {
      let findChecked = newOrder.properties.find((checkIt) => {
        return checkIt.id === eachItem.id;
      });
      if (findChecked === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  //property checked
  const checkCheckedPropertyQuantity = (propertyItem) => {
    if (newOrder.properties !== null) {
      let theItem = newOrder.properties.find((findThis) => {
        return findThis.id === propertyItem.id;
      });
      if (theItem !== undefined) {
        return theItem.quantity;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  };

  //set propertyqty
  const handlePropertyQty = (propertyItem, action) => {
    let newTemp = [];
    if (newOrder.properties !== null && newOrder.properties.length > 0) {
      newOrder.properties.map((pushThis) => {
        if (pushThis.id === propertyItem.id) {
          if (action === "+") {
            pushThis.quantity = pushThis.quantity + 1;
            newTemp.push(pushThis);
          } else {
            if (pushThis.quantity > 1) {
              pushThis.quantity = pushThis.quantity - 1;
            }
            newTemp.push(pushThis);
          }
        } else {
          newTemp.push(pushThis);
        }
      });
      setNewOrder({
        ...newOrder,
        properties: newTemp,
      });
    }
  };

  //get already ordered qty
  const handleAlreadyOrderedQty = (id) => {
    let temp = 0;
    if (orderDetails.items.length > 0) {
      orderDetails.items.map((item) => {
        if (parseInt(item.item.id) === id) {
          temp += parseInt(item.quantity);
        }
      });
    }
    return temp;
  };

  //add to cart
  const handleOrder = () => {
    // check if manage stock is enable
    if (showManageStock) {
      if (
        handleGetStock(foodItems.selectedItem.id) >
        handleAlreadyOrderedQty(foodItems.selectedItem.id)
      ) {
        let tempPrice = 0;
        //if no variation
        if (newOrder.variation !== null) {
          tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
        } else {
          tempPrice = parseFloat(foodItems.selectedItem.price);
        }

        if (newOrder.properties !== null && newOrder.properties.length > 0) {
          let tempPropertyPrice = 0;
          newOrder.properties.map((propertyItem, propertyItemIndex) => {
            tempPropertyPrice =
              tempPropertyPrice +
              parseFloat(propertyItem.extra_price) * propertyItem.quantity;
          });
          tempPrice = tempPrice + tempPropertyPrice;
        }

        let tempOrderItem = {
          item: foodItems.selectedItem,
          quantity: newOrder.quantity,
          variation: newOrder.variation,
          properties: newOrder.properties,
          subTotal: tempPrice,
        };
        setOrderDetails({
          ...orderDetails,
          items: [...orderDetails.items, tempOrderItem],
        });
        setCardDetails(orderDetails);
        setShowVariation(false);
        setShowCheckout(false);
        setShowCart(true);
      } else {
        toast.error(`${_t(t("Stock Out"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      let tempPrice = 0;
      //check variation
      if (newOrder.variation !== null) {
        tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
      } else {
        tempPrice = parseFloat(foodItems.selectedItem.price);
      }

      // check properties
      if (newOrder.properties !== null && newOrder.properties.length > 0) {
        let tempPropertyPrice = 0;
        newOrder.properties.map((propertyItem, propertyItemIndex) => {
          tempPropertyPrice =
            tempPropertyPrice +
            parseFloat(propertyItem.extra_price) * propertyItem.quantity;
        });
        tempPrice = tempPrice + tempPropertyPrice;
      }

      let tempOrderItem = {
        item: foodItems.selectedItem,
        quantity: newOrder.quantity,
        variation: newOrder.variation,
        properties: newOrder.properties,
        subTotal: tempPrice,
      };

      setOrderDetails({
        ...orderDetails,
        items: [...orderDetails.items, tempOrderItem],
      });

      setCardDetails({
        ...orderDetails,
        items: [...orderDetails.items, tempOrderItem],
      });

      setShowVariation(false);
      setShowCheckout(false);
      setShowCart(true);
    }
  };
  //add to cart
  const handleOrderSingle = (foodItem) => {
    // check if manage stock is enable
    if (showManageStock) {
      if (handleGetStock(foodItem.id) > handleAlreadyOrderedQty(foodItem.id)) {
        let tempPrice = 0;
        //if no variation
        if (newOrder.variation !== null) {
          tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
        } else {
          tempPrice = parseFloat(foodItem.price);
        }

        if (newOrder.properties !== null && newOrder.properties.length > 0) {
          let tempPropertyPrice = 0;
          newOrder.properties.map((propertyItem, propertyItemIndex) => {
            tempPropertyPrice =
              tempPropertyPrice +
              parseFloat(propertyItem.extra_price) * propertyItem.quantity;
          });
          tempPrice = tempPrice + tempPropertyPrice;
        }

        let tempOrderItem = {
          item: foodItem,
          quantity: newOrder.quantity,
          variation: null,
          properties: null,
          subTotal: tempPrice,
        };
        setOrderDetails({
          ...orderDetails,
          items: [...orderDetails.items, tempOrderItem],
        });
        setCardDetails(orderDetails);
        setShowVariation(false);
        setShowCheckout(false);
        setShowCart(true);
      } else {
        toast.error(`${_t(t("Stock Out"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      let tempPrice = 0;
      //check variation
      if (newOrder.variation !== null) {
        tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
      } else {
        tempPrice = parseFloat(foodItem.price);
      }

      // check properties
      if (newOrder.properties !== null && newOrder.properties.length > 0) {
        let tempPropertyPrice = 0;
        newOrder.properties.map((propertyItem, propertyItemIndex) => {
          tempPropertyPrice =
            tempPropertyPrice +
            parseFloat(propertyItem.extra_price) * propertyItem.quantity;
        });
        tempPrice = tempPrice + tempPropertyPrice;
      }
      var vatprice = 0;
      if (foodItem.tax > 0) {
        var vatprice = (tempPrice * foodItem.tax) / 100;
      }

      let tempOrderItem = {
        item: foodItem,
        quantity: newOrder.quantity,
        variation: null,
        properties: null,
        vat: foodItem.tax,
        vatprice: vatprice,
        subTotal: tempPrice,
      };

      setOrderDetails({
        ...orderDetails,
        items: [...orderDetails.items, tempOrderItem],
      });
      setTimeout(() => {
        setCardDetails({
          ...orderDetails,
          items: [...orderDetails.items, tempOrderItem],
        });
      }, 1000);

      setShowVariation(false);
      setShowCheckout(false);
      setShowCart(true);
    }
  };
  const getCartVatList = (orderDetailsData) => {
    if (orderDetailsData.items && orderDetailsData.items.length > 0) {
      let Vatarrat = [];
      orderDetailsData.items.map((cartItem, cartItemIndex) => {
        if (cartItem.item.tax > 0) {
          if (Vatarrat.hasOwnProperty(cartItem.item.tax)) {
            Vatarrat[cartItem.item.tax] =
              Vatarrat[cartItem.item.tax] +
              (cartItem.subTotal * cartItem.item.tax) / 100;
          } else {
            Vatarrat[cartItem.item.tax] =
              (cartItem.subTotal * cartItem.item.tax) / 100;
          }
        }
      });
      if (Vatarrat.length > 0) {
        return Vatarrat.map((item, index) => {
          return (
            <div className="d-flex align-items-center justify-content-between my-2">
              <div className="kh-drawer__text text-capitalize">
                {_t(t("vat"))} ({index}%)
              </div>
              <div className="kh-drawer__text text-right text-uppercase sm-text">
                {formatPrice(item)} Fcfp
              </div>
            </div>
          );
        });
      }
    } else {
      return (
        <div className="d-flex align-items-center justify-content-between my-2">
          <div className="kh-drawer__text text-capitalize">
            {_t(t("vat"))} (0%)
          </div>
          <div className="kh-drawer__text text-right text-uppercase sm-text">
            0 Fcfp
          </div>
        </div>
      );
    }
  };

  // addtocartItems
  // const addtocartItems = (e) => {
  //   e.preventDefault();
  //   alert("Hey!! I am cool!!!");
  // }

  //stock
  const handleGetStock = (id) => {
    if (orderDetails.branch === null) {
      return 0;
    }
    let stock = foodStockWeb.find((item) => {
      return (
        parseInt(item.food_id) === parseInt(id) &&
        parseInt(item.branch_id) === parseInt(orderDetails.branch)
      );
    });
    if (stock === undefined || stock.qty < 0) {
      return 0;
    }
    return stock.qty;
  };

  //order quantity
  const handleQty = (cartItemIndex, action) => {
    let oldItems = [];
    orderDetails.items.map((orderItem, orderItemIndex) => {
      if (orderItemIndex !== cartItemIndex) {
        oldItems.push(orderItem);
      } else {
        if (action === "+") {
          let temp = orderItem;

          // check manage stock enable
          if (showManageStock) {
            let stock = handleGetStock(temp.item.id);
            if (stock > handleAlreadyOrderedQty(temp.item.id)) {
              temp.quantity = temp.quantity + 1;
            } else {
              toast.error(`${_t(t("Reached Stock Limit"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
          } else {
            temp.quantity = temp.quantity + 1;
          }

          let tempPrice = 0;
          //if no variation

          if (newOrder.variation !== null) {
            tempPrice = parseFloat(
              newOrder.variation.food_with_variation_price
            );
          } else {
            tempPrice = parseFloat(temp.item.price);
          }

          if (newOrder.properties !== null && newOrder.properties.length > 0) {
            let tempPropertyPrice = 0;
            newOrder.properties.map((propertyItem, propertyItemIndex) => {
              tempPropertyPrice =
                tempPropertyPrice +
                parseFloat(propertyItem.extra_price) * propertyItem.quantity;
            });
            tempPrice = tempPrice + tempPropertyPrice;
          }

          temp.subTotal = tempPrice * temp.quantity;
          oldItems.push(temp);
        } else {
          let temp = orderItem;
          if (temp.quantity > 1) {
            temp.quantity = temp.quantity - 1;
            let tempPrice = 0;
            //if no variation
            if (newOrder.variation !== null) {
              tempPrice = parseFloat(
                newOrder.variation.food_with_variation_price
              );
            } else {
              tempPrice = parseFloat(temp.item.price);
            }

            if (
              newOrder.properties !== null &&
              newOrder.properties.length > 0
            ) {
              let tempPropertyPrice = 0;
              newOrder.properties.map((propertyItem, propertyItemIndex) => {
                tempPropertyPrice =
                  tempPropertyPrice +
                  parseFloat(propertyItem.extra_price) * propertyItem.quantity;
              });
              tempPrice = tempPrice + tempPropertyPrice;
            }

            temp.subTotal = tempPrice * temp.quantity;
            oldItems.push(temp);
          } else {
            oldItems.splice(orderItemIndex, 1);
            console.log(oldItems);
          }
        }
      }
    });
    setOrderDetails({ ...orderDetails, items: oldItems });
    if (oldItems.length == 0) {
      setCardDetails(null);
    } else {
      setCardDetails({ ...orderDetails, items: oldItems });
    }
  };

  //calculate total
  const getTotal = () => {
    let total = 0;
    if (orderDetails.items.length > 0) {
      orderDetails.items.map((temp) => {
        total += temp.subTotal;
      });
    }
    return total;
  };

  //calculate vat
  const getVat = () => {
    let vat = 0;
    let rate = parseFloat(getSystemSettings(generalSettings, "type_vat"));
    //vat = (getTotal() * rate) / 100;
    if (orderDetails.items.length > 0) {
      orderDetails.items.map((temp) => {
        if (temp.item.tax > 0) {
          var spvat = (temp.subTotal * temp.item.tax) / 100;
          vat = vat + spvat;
        }
      });
    }
    return vat;
  };

  //handle changes
  const handleChange = (e) => {
    e.preventDefault();
    setOrderDetails({
      ...orderDetails,
      "[e.target.name]": e.target.value,
    });

    setOrderDetails({
      ...orderDetails,
      workPeriod: {
        id: "1",
        date: "Saturday, 30 Apr, 2022",
        branch_name: "La Maison du Pain Calédonien",
        started_by: "Admin",
        started_at: "173708471",
        ended_at: null,
        ended_by: null,
        branch_id: "11",
        token: 6,
        created_at: "2021-08-03T10:21:54.000000Z",
        updated_at: "2022-04-12T21:20:56.000000Z",
      },
      branch: 11,
    });
  };

  //handle changes
  const handleChangeTime = (timeitem) => {
    setOrderDetails({
      ...orderDetails,
      pickuptime: timeitem,
      branch: 11,
    });
  };

  const handleChangeDate = (dateval) => {
    setOrderDetails({
      ...orderDetails,
      pickupdate: dateval,
      branch: 11,
    });
  };

  // stripe payment states
  const [stripeDetails, setstripeDetails] = useState({
    stripe_card_name: null,
    stripe_card_number: null,
    stripe_card_cvc: null,
    stripe_card_exp_month: null,
    stripe_card_exp_year: null,
    stripe_zip_code: null,
  });

  //submit order cod
  const handleOrderSubmit = (e) => {
    setOrderDetails({
      ...orderDetails,
      uploading: true,
    });
    e.preventDefault();
    let url = BASE_URL + "/website/order";
    let formData = orderDetails;
    formData.subTotal = getTotal();
    formData.vat = getVat();
    formData.delivery_charge = branchDeliveryCharge;
    return axios
      .post(url, orderDetails, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "ended") {
          setShowCheckout(false);
          setFoodStockWeb(res.data);
          setOrderDetails({
            items: [],
            branch: null,
            workPeriod: null,
            workPeriodStatus: false,
            address:
              authUserInfo.details && authUserInfo.details.address
                ? authUserInfo.details.address
                : "none",
            name: authUserInfo.details && authUserInfo.details.name,
            phn_no: authUserInfo.details && authUserInfo.details.phn_no,
            pickupdate: null,
            pickuptime: null,
            note: "none",
            payment_type: "COD",
            uploading: false,
          });
          setCardDetails(null);
          toast.success(`${_t(t("Your order has been placed"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          setOrderDetails({
            ...orderDetails,
            uploading: false,
          });
          setCardDetails(null);
        }
      })
      .catch((err) => {
        setOrderDetails({
          ...orderDetails,
          uploading: false,
        });
        setCardDetails(orderDetails);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // handle stripe on change
  const onchangehandleStripePayment = (e) => {
    setstripeDetails({ ...stripeDetails, [e.target.name]: e.target.value });
  };

  // stripe handle submit
  const onsubmithandleStripePayment = (e) => {
    e.preventDefault();
    document.getElementById("stripePaynow").innerHTML = "Please wait...";
    let url = BASE_URL + "/settings/get-stripe-data";

    let formdata = stripeDetails;
    formdata.subTotal = getTotal();
    formdata.vat = getVat();
    formdata.delivery_charge = branchDeliveryCharge;
    return axios
      .post(url, formdata, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // make reuseble payment component
        if (res.data.status == 422) {
          document.getElementById("stripePaynow").innerHTML = "Pay Now";
          toast.error(`${_t(t("Please enter valid info"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          const payment_id = res.data.card.id;
          const payment_type = "STRIPE";

          // send data to serve start
          setOrderDetails({
            ...orderDetails,
            uploading: true,
          });
          e.preventDefault();
          let url = BASE_URL + "/website/order";
          let formData = orderDetails;
          formData.subTotal = getTotal();
          formData.vat = getVat();
          formData.payment_type = payment_type;
          formData.payment_id = payment_id;
          formData.delivery_charge = branchDeliveryCharge;
          return axios
            .post(url, orderDetails, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              if (res.data !== "ended") {
                setShowCheckout(false);
                setFoodStockWeb(res.data);
                setOrderDetails({
                  items: [],
                  branch: null,
                  workPeriod: null,
                  workPeriodStatus: false,
                  address: authUserInfo.details && authUserInfo.details.address,
                  name: authUserInfo.details && authUserInfo.details.name,
                  phn_no: authUserInfo.details && authUserInfo.details.phn_no,
                  pickupdate: PickupDate,
                  pickuptime: PickupTime,
                  note: null,
                  payment_type: "COD",
                  uploading: false,
                });

                toast.success(`${_t(t("Your order has been placed"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              } else {
                toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
                setOrderDetails({
                  ...orderDetails,
                  uploading: false,
                });
              }
              document.getElementById("stripePaynow").innerHTML = "Pay Now";
            })
            .catch((err) => {
              setOrderDetails({
                ...orderDetails,
                uploading: false,
              });

              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
              document.getElementById("stripePaynow").innerHTML = "Pay Now";
            });
          // send data to serve end
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //set default language on site load
  const handleOnLoadDefaultLang = () => {
    let localLang = localStorage.i18nextLng;
    if (localLang) {
      if (localLang === undefined || localLang.includes("en-")) {
        navLanguageList &&
          navLanguageList.map((item) => {
            if (item.is_default === true) {
              i18n.changeLanguage(item.code);
              setDefaultLang(item);
            }
            return true;
          });
      } else {
        const temp =
          navLanguageList &&
          navLanguageList.find((item) => {
            return item.code === localLang;
          });
        setDefaultLang(temp);
        i18n.changeLanguage(localLang);
      }
    }
  };

  //change language to selected
  const handleDefaultLang = (e) => {
    let lang =
      navLanguageList &&
      navLanguageList.find((theItem) => {
        return theItem.id === parseInt(e.target.value);
      });
    i18n.changeLanguage(lang.code);
    setDefaultLang(lang);
    toast.success(`${_t(t("Language has been switched!"))}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };

  //set default currency on site load
  const handleOnLoadDefaultCurrency = () => {
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    if (localCurrency === null) {
      navCurrencyList &&
        navCurrencyList.map((item) => {
          if (item.is_default === true) {
            setDefaultCurrency(item);
            localStorage.setItem("currency", JSON.stringify(item));
          }
          return true;
        });
    } else {
      const temp =
        navCurrencyList &&
        navCurrencyList.find((item) => {
          return item.code === localCurrency.code;
        });
      setDefaultCurrency(temp);
    }
  };

  //change currency to selected
  const handleDefaultCurrency = (e) => {
    let item =
      navCurrencyList &&
      navCurrencyList.find((theItem) => {
        return theItem.id === parseInt(e.target.value);
      });
    localStorage.setItem("currency", JSON.stringify(item));
    setDefaultCurrency(item);
    toast.success(`${_t(t("Currency has been changed!"))}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };

  //get selected branch
  const getSelectedBranch = (id) => {
    if (orderDetails && orderDetails.branch !== null) {
      if (id === orderDetails.branch.id) {
        return true;
      }
    }
    return false;
  };

  //paypal integration
  const initialOptions = {
    // "client-id": `${paypal_client_id}`,
    "client-id":
      "AWOafqislzl8zx6-w5BwIOu9p-7DXKNt3Ly4hGzXYNRYBKJkY_yrUcAYSc5RP6YFz_ckikuYoDoBs9NK",
    currency: "USD",
    intent: "capture",
  };

  return (
    <>
      {/* paypal modal */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLongTitle">
                pay with paypal
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  forceReRender={[orderDetails]}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: formatPrice(getTotal() + getVat()),
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={(data, actions) => {
                    return actions.order.capture().then(function (orderData) {
                      var transaction_id =
                        orderData.purchase_units[0].payments.captures[0].id;
                      console.log(transaction_id);
                      // order
                      setOrderDetails({
                        ...orderDetails,
                        uploading: true,
                      });

                      let url = BASE_URL + "/website/order";
                      let formData = orderDetails;
                      formData.subTotal = getTotal();
                      formData.vat = getVat();
                      formData.payment_type = "PAYPAL";
                      formData.payment_id = transaction_id;
                      console.log(formData);
                      return axios
                        .post(url, orderDetails, {
                          headers: { Authorization: `Bearer ${getCookie()}` },
                        })
                        .then((res) => {
                          if (res.data !== "ended") {
                            setShowCheckout(false);
                            setFoodStockWeb(res.data);
                            setOrderDetails({
                              items: [],
                              branch: null,
                              workPeriod: null,
                              workPeriodStatus: false,
                              address:
                                authUserInfo.details &&
                                authUserInfo.details.address,
                              name:
                                authUserInfo.details &&
                                authUserInfo.details.name,
                              phn_no:
                                authUserInfo.details &&
                                authUserInfo.details.phn_no,
                              pickupdate: PickupDate,
                              pickuptime: PickupTime,
                              note: null,
                              payment_type: "COD",
                              uploading: false,
                            });

                            toast.success(
                              `${_t(t("Your order has been placed"))}`,
                              {
                                position: "bottom-center",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                              }
                            );
                            history.push("/");
                          } else {
                            toast.error(
                              `${_t(t("Sorry, this branch is closed now"))}`,
                              {
                                position: "bottom-center",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                              }
                            );
                            setOrderDetails({
                              ...orderDetails,
                              uploading: false,
                            });
                          }
                        })
                        .catch((err) => {
                          setOrderDetails({
                            ...orderDetails,
                            uploading: false,
                          });

                          toast.error(`${_t(t("Please try again"))}`, {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                          });
                        });
                      // end here
                    });
                  }}
                />
              </PayPalScriptProvider>
            </div>
          </div>
        </div>
      </div>
      {/* paypal modal */}

      {/* strip modal */}
      <div
        className="modal fade"
        id="exampleModalCenterStrip"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLongTitle">
                pay with credit card
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={onsubmithandleStripePayment}>
                <div className="form-row row">
                  <div className="col-xs-12 form-group required">
                    <label className="control-label">Name on Card</label>
                    <input
                      onChange={onchangehandleStripePayment}
                      className="form-control"
                      required
                      name="stripe_card_name"
                      size="4"
                      type="text"
                    />
                  </div>
                </div>

                <div className="form-row row">
                  <div className="col-xs-12 form-group required">
                    <label className="control-label">Address zip code</label>
                    <input
                      onChange={onchangehandleStripePayment}
                      className="form-control"
                      required
                      name="stripe_zip_code"
                      size="4"
                      type="text"
                    />
                  </div>
                </div>

                <div className="form-row row">
                  <div className="col-xs-12 form-group card required">
                    <label className="control-label">Card Number</label>{" "}
                    <input
                      className="form-control card-number"
                      onChange={onchangehandleStripePayment}
                      name="stripe_card_number"
                      size="20"
                      required
                      type="text"
                    />
                  </div>
                </div>

                <div className="form-row row">
                  <div className="col-xs-12 col-md-4 form-group cvc required">
                    <label className="control-label">CVC</label>
                    <input
                      autoComplete="off"
                      className="form-control card-cvc"
                      placeholder="ex. 311"
                      size="4"
                      name="stripe_card_cvc"
                      required
                      onChange={onchangehandleStripePayment}
                      type="text"
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 form-group expiration required">
                    <label className="control-label">Expiration Month</label>
                    <input
                      className="form-control card-expiry-month"
                      placeholder="MM"
                      size="2"
                      type="text"
                      required
                      name="stripe_card_exp_month"
                      onChange={onchangehandleStripePayment}
                    />
                  </div>
                  <div className="col-xs-12 col-md-4 form-group expiration required">
                    <label className="control-label">Expiration Year</label>
                    <input
                      className="form-control card-expiry-year"
                      placeholder="YYYY"
                      size="4"
                      type="text"
                      required
                      name="stripe_card_exp_year"
                      onChange={onchangehandleStripePayment}
                    />
                  </div>
                </div>

                {/* <div className='form-row row'>
                  <div className='col-md-12 error form-group hide'>
                    <div className='alert-danger alert'>Please correct the errors and try
                      again.</div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-xs-12">
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      id="stripePaynow"
                      type="submit"
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* strip modal */}

      <Helmet>
        <title>
          {generalSettings && getSystemSettings(generalSettings, "siteName")}
        </title>
        <link rel="stylesheet" href="/website/css/animate.css" />
        <link rel="stylesheet" href="/website/css/meanmenu.min.css" />
        <link rel="stylesheet" href="./website/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/website/css/font-awsome-all.min.css" />
        <link rel="stylesheet" href="/website/css/magnific-popup.css" />
        <link rel="stylesheet" href="/website/css/slick.css" />
        <link rel="stylesheet" href="/website/css/jquery-ui.css" />
        <link rel="stylesheet" href="/website/css/style.css" />

        <script src="/website/js/vendor/jquery-2.2.4.min.js"></script>
        <script src="/website/js/vendor/bootstrap.min.js"></script>
        <script src="./website/js/vendor/jquery.meanmenu.min.js"></script>
        <script src="/website/js/vendor/jquery.magnific-popup.min.js"></script>
        <script src="/website/js/vendor/slick.min.js"></script>
        <script src="/website/js/vendor/counterup.min.js"></script>
        <script src="/website/js/vendor/countdown.js"></script>
        <script src="/website/js/vendor/waypoints.min.js"></script>
        <script src="/website/js/vendor/jquery-ui.js"></script>
        <script src="/website/js/vendor/isotope.pkgd.min.js"></script>
        <script src="/website/js/vendor/easing.min.js"></script>
        <script src="/website/js/vendor/wow.min.js"></script>
        <script src="/website/js/simplebar.js"></script>
        <script src="/website/js/main.js"></script>
      </Helmet>

      {/* <!-- Preloader Starts --> */}
      <div className={`preloader02 ${!loading && "d-none"}`} id="preloader02">
        <div className="preloader-inner">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>

      {/* Floating Cart button*/}
      {getSystemSettings(generalSettings, "isshopactive") == 1 ? (
        <div
          className={`kh-floating-cart pointer-cursor ${
            foodItems.list ? "" : "d-none"
          } `}
          onClick={() => {
            setShowCart(true);
          }}
        >
          <div className="kh-floating-cart__container">
            <span className="kh-floating-cart__heading">Panier</span>
            <span className="kh-floating-cart__text text-capitalize pb-2">
              <i className="fas fa-shopping-cart"></i>{" "}
              {orderDetails.items ? orderDetails.items.length : 0}{" "}
              {_t(t("item"))}
            </span>
            <span className="kh-floating-cart__money text-uppercase py-2 text-center">
              {currencySymbolLeft()}
              {formatPrice(getTotal() + getVat() + branchDeliveryCharge)}
              {currencySymbolRight()}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* Floating Cart End */}

      {/* cart drawer */}
      {showCart && (
        <div className={`kh-drawer`}>
          <div className="kh-drawer__container">
            <div className="kh-drawer__head pyyy-3 my-3 d-flex align-items-center justify-content-between">
              <span className="kh-drawer__head-title text-capitalize">
                {getSystemSettings(generalSettings, "siteName")}
              </span>
              <button
                type="button"
                className="kh-drawer__close"
                onClick={() => {
                  setShowCart(false);
                  setShowCheckout(false);
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="kh-drawer__body pyyy-3" data-simplebar>
              <div className="kh-drawer__container">
                <ul className="kh-drawer__list" style={{ minHeight: "300px" }}>
                  {orderDetails.items && orderDetails.items.length > 0 ? (
                    orderDetails.items.map((cartItem, cartItemIndex) => {
                      return (
                        <li
                          className="kh-drawer__list-item border-bottom"
                          key={cartItemIndex}
                        >
                          <div className="kh-drawer__product py-1 d-flex justify-content-between align-items-center">
                            <a
                              href="#"
                              className="kh-drawer__product-title text-capitalize pointer-cursor"
                            >
                              {cartItem.item.name}
                            </a>
                            <span className="kh-drawer__product-price text-uppercase text-right">
                              {currencySymbolLeft()}
                              {formatPrice(cartItem.subTotal)}
                              {currencySymbolRight()}
                            </span>
                          </div>

                          {/* if variation exiest */}
                          <div className="kh-drawer__product d-flex justify-content-between ">
                            {cartItem.variation && (
                              <a
                                href="#"
                                className="kh-drawer__product-subtitle text-capitalize pointer-cursor"
                              >
                                {cartItem.variation && (
                                  <span className="d-block">
                                    + {cartItem.variation.variation_name}
                                  </span>
                                )}
                              </a>
                            )}
                          </div>

                          {/* if properties exiest */}
                          <div className="kh-drawer__product d-flex justify-content-between ">
                            {cartItem.properties &&
                              cartItem.properties.length > 0 && (
                                <a
                                  href="#"
                                  className="kh-drawer__product-subtitle text-capitalize pointer-cursor"
                                >
                                  {cartItem.properties.map((eachProperty) => {
                                    return (
                                      <span className="d-block">
                                        + {eachProperty.name}
                                        {eachProperty.quantity > 1
                                          ? "(" + eachProperty.quantity + ")"
                                          : ""}
                                      </span>
                                    );
                                  })}
                                </a>
                              )}
                            {!cartItem.properties && (
                              <a
                                href="#"
                                className="kh-drawer__product-subtitle text-capitalize pointer-cursor"
                              >
                                <span className="d-block"></span>
                              </a>
                            )}

                            <div className="kh-drawer__product-quantity d-flex justify-content-between align-items-center">
                              <button
                                className="kh-drawer__product-quantity-decrease kh-drawer__product-quantity-btn"
                                onClick={() => {
                                  handleQty(cartItemIndex, "-");
                                }}
                              >
                                <i
                                  className={`fas ${
                                    cartItem.quantity > 1
                                      ? "fa-minus"
                                      : "fa-trash"
                                  }`}
                                ></i>
                              </button>
                              <div className="kh-drawer__product-quantity-value text-center flex-grow-1">
                                {cartItem.quantity}
                              </div>
                              <button
                                className="kh-drawer__product-quantity-increase kh-drawer__product-quantity-btn"
                                onClick={() => {
                                  handleQty(cartItemIndex, "+");
                                }}
                              >
                                <i className="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <div
                      className="text-center"
                      style={{ minHeight: "300px", paddingTop: "100px" }}
                    >
                      {_t(t("No item added"))}
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div className="kh-drawer__foot pyyy-3 my-3">
              <div className="kh-drawer__container">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <div className="kh-drawer__text text-capitalize">
                    {_t(t("subtotal"))}
                  </div>
                  <div className="kh-drawer__text text-right text-uppercase sm-text">
                    {currencySymbolLeft()}
                    {formatPrice(getTotal())}
                    {currencySymbolRight()}
                  </div>
                </div>
                {/*<div className="d-flex align-items-center justify-content-between my-2">
                
                  <div className="kh-drawer__text text-capitalize">
                    {getSystemSettings(generalSettings, "vat_system") ===
                      "igst"
                      ? `${_t(t("vat"))}(${getSystemSettings(
                        generalSettings,
                        "type_vat"
                      )}%)`
                      : getSystemSettings(generalSettings, "vat_system") ===
                        "cgst" ? "cgst+sgst" +
                        "(" +
                        getSystemSettings(generalSettings, "cgst") + "%" +
                        "+" +
                        getSystemSettings(generalSettings, "sgst") +
                      "%)" :
                        `${_t(t("tax"))}(${getSystemSettings(
                          generalSettings,
                          "tax"
                        )}%)`}

                  </div>
                  <div className="kh-drawer__text text-right text-uppercase sm-text">
                    {currencySymbolLeft()}
                    {formatPrice(getVat())}
                    {currencySymbolRight()}
                  </div>
                </div> */}

                {getCartVatList(orderDetails)}

                {/* <div className="d-flex align-items-center justify-content-between my-2">
                  <div className="kh-drawer__text text-capitalize">{_t(t("Delivery Charge"))}</div>
                  <div className="kh-drawer__text text-right text-uppercase sm-text">
                    {currencySymbolLeft()}
                    {branchDeliveryCharge}
                    {currencySymbolRight()}
                  </div>
                </div> */}

                <div className="d-flex align-items-center justify-content-between my-2">
                  <div className="kh-drawer__text text-capitalize">
                    {_t(t("Total"))}
                  </div>
                  <div className="kh-drawer__text text-right text-uppercase sm-text">
                    {currencySymbolLeft()}
                    {formatPrice(getTotal() + getVat() + branchDeliveryCharge)}
                    {currencySymbolRight()}
                  </div>
                </div>

                {getCookie() === "undefined1" ? (
                  <NavLink to="/login" className="btn w-100 text-uppercase">
                    {_t(t("Login"))}
                  </NavLink>
                ) : (
                  <>
                    {orderDetails.items.length > 0 ? (
                      <button
                        className="btn w-100 text-uppercase 1"
                        onClick={() => {
                          setShowCart(false);
                          setShowCheckout(true);
                          /*setShowPaymentForm(
                            getTotal() + getVat() + branchDeliveryCharge,
                            orderDetails
                          );*/
                        }}
                      >
                        {_t(t("go to checkout"))}
                      </button>
                    ) : (
                      <button
                        className="btn w-100 text-uppercase 2"
                        onClick={() => {
                          setShowCart(false);
                          setShowCheckout(true);
                        }}
                        disabled
                      >
                        {_t(t("go to checkout"))}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* cart drawer ends*/}

      {/* Checkout drawer */}
      {showCheckout && (
        <div className="kh-drawer">
          <form onSubmit={handleOrderSubmit}>
            <div className="kh-drawer__container">
              <div className="kh-drawer__head py-3 my-3 d-flex align-items-center justify-content-between">
                <button
                  type="button"
                  className="kh-drawer__back"
                  onClick={() => {
                    setShowCart(true);
                    setShowCheckout(false);
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
                <span className="kh-drawer__head-title text-capitalize">
                  {getSystemSettings(generalSettings, "siteName")}
                </span>
                <button
                  type="button"
                  className="kh-drawer__close"
                  onClick={() => {
                    setShowCart(false);
                    setShowCheckout(false);
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="kh-drawer__body py-3" data-simplebar>
                <div className="kh-drawer__container">
                  <ul className="kh-drawer__list">
                    <li className="kh-drawer__list-item">
                      <div className="kh-drawer__details">
                        <div className="kh-drawer__details-head d-flex align-items-center">
                          <div className="kh-drawer__details-count text-center d-flex align-items-center justify-content-center">
                            {_t(t("1"))}
                          </div>
                          <div className="kh-drawer__details-title text-capitalize">
                            {_t(t("Click and collect"))}
                          </div>
                        </div>
                        <div className="kh-drawer__details-body my-3">
                          <div className="form-group datepickerwapper">
                            <label
                              htmlFor="location"
                              className="sm-text text-capitalize"
                            >
                              {_t(t("Pick up date"))}
                            </label>

                            <DatePicker
                              locale="fr"
                              selected={PickupDate}
                              onChange={(date) => {
                                setPickupDate(date);
                                handleChangeDate(date);
                              }}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control rounded-0 sm-text"
                              placeholderText={_t(t("Pick up date"))}
                              shouldCloseOnSelect={false}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="location"
                              className="sm-text text-capitalize"
                            >
                              {_t(t("Pick up time"))}
                            </label>

                            <TimePicker
                              onChange={(newValue) => {
                                setPickupTime(newValue);
                                handleChangeTime(newValue);
                              }}
                              required
                              value={PickupTime}
                            />
                          </div>
                          {/*<div className="form-group">
                            <label
                              htmlFor="location"
                              className="sm-text text-capitalize"
                            >
                              {_t(t("enter delivery address"))}
                            </label>
                            <textarea
                              className="form-control sm-text"
                              rows="3"
                              placeholder={_t(t("Address"))}
                              required
                              name="address"
                              onChange={handleChange}
                            >
                              {orderDetails.address}
                            </textarea>
                          </div> 
                          <div className="form-group">
                            <label
                              htmlFor="note"
                              className="sm-text text-capitalize"
                            >
                              {_t(t("note to rider"))}
                            </label>
                            <input
                              type="text"
                              className="form-control rounded-0 sm-text"
                              id="note"
                              onChange={handleChange}
                              name="note"
                              placeholder={_t(t("note to rider"))}
                              value={orderDetails.note}
                            />
                          </div>
                          */}
                        </div>
                      </div>
                    </li>
                    <li className="kh-drawer__list-item">
                      <div className="kh-drawer__details">
                        <div className="kh-drawer__details-head d-flex align-items-center">
                          <div className="kh-drawer__details-count text-center d-flex align-items-center justify-content-center">
                            {_t(t("2"))}
                          </div>
                          <div className="kh-drawer__details-title text-capitalize">
                            {_t(t("Personal Details"))}
                          </div>
                        </div>
                        <div className="kh-drawer__details-body my-3">
                          <div className="form-group">
                            <label
                              htmlFor="firstName"
                              className="sm-text text-capitalize"
                            >
                              {_t(t("name"))}
                            </label>
                            <input
                              type="text"
                              className="form-control rounded-0 sm-text"
                              id="firstName"
                              placeholder={_t(t("name"))}
                              name="name"
                              disabled
                              value={orderDetails.name}
                            />
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="phone"
                              className="sm-text text-capitalize"
                            >
                              {_t(t("mobile number"))}
                            </label>
                            <input
                              type="text"
                              className="form-control rounded-0 sm-text"
                              id="phone"
                              placeholder={_t(t("mobile number"))}
                              name="phn_no"
                              required
                              onChange={handleChange}
                              value={orderDetails.phn_no}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="kh-drawer__foot py-3 my-3">
                <div className="kh-drawer__container">
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <div className="kh-drawer__text text-capitalize">
                      {_t(t("subtotal"))}
                    </div>
                    <div className="kh-drawer__text text-right text-uppercase sm-text">
                      {currencySymbolLeft()}
                      {formatPrice(getTotal())}
                      {currencySymbolRight()}
                    </div>
                  </div>
                  {/*<div className="d-flex align-items-center justify-content-between my-2">
                    <div className="kh-drawer__text text-capitalize">
                      {getSystemSettings(generalSettings, "vat_system") ===
                        "igst"
                        ? `${_t(t("vat"))}(${getSystemSettings(
                          generalSettings,
                          "type_vat"
                        )}%)`
                        : getSystemSettings(generalSettings, "vat_system") ===
                          "cgst" ? "cgst+sgst" +
                          "(" +
                          getSystemSettings(generalSettings, "cgst") + "%" +
                          "+" +
                          getSystemSettings(generalSettings, "sgst") +
                        "%)" :
                          `${_t(t("tax"))}(${getSystemSettings(
                            generalSettings,
                            "tax"
                          )}%)`}
                    </div>
                    <div className="kh-drawer__text text-right text-uppercase sm-text">
                      {currencySymbolLeft()}
                      {formatPrice(getVat())}
                      {currencySymbolRight()}
                    </div>
                  </div> */}
                  {getCartVatList(orderDetails)}

                  {/* <div className="d-flex align-items-center justify-content-between my-2">
                    <div className="kh-drawer__text text-capitalize">{_t(t("Delivery Charge"))}</div>
                    <div className="kh-drawer__text text-right text-uppercase sm-text">
                      {currencySymbolLeft()}
                      {branchDeliveryCharge}
                      {currencySymbolRight()}
                    </div>
                  </div>
                  */}
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <div className="kh-drawer__text text-capitalize">
                      {_t(t("Total"))}
                    </div>
                    <div className="kh-drawer__text text-right text-uppercase sm-text">
                      {currencySymbolLeft()}
                      {formatPrice(
                        getTotal() + getVat() + branchDeliveryCharge
                      )}
                      {currencySymbolRight()}
                    </div>
                  </div>

                  {getCookie() === undefined ? (
                    <NavLink to="/login" className="btn w-100 text-uppercase">
                      {_t(t("Login"))}
                    </NavLink>
                  ) : (
                    <>
                      {orderDetails.uploading ? (
                        modalLoading(2)
                      ) : (
                        <>
                          <div
                            data-simplebar
                            className="d-flex align-items-center justify-content-between"
                          >
                            {/* place order */}
                            <button
                              type="button"
                              className="btn-danger payment-btn btn-sm  px-4 text-capitalize "
                              onClick={() => {
                                setShowPaymentForm(
                                  getTotal() + getVat() + branchDeliveryCharge,
                                  orderDetails,
                                  getVat()
                                );
                              }}
                            >
                              {_t(t("Place Order"))}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {getCookie() != undefined ? (
                    <>
                      <div className="checkoutform py-3"></div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {/* Checkout drawer ends */}

      {/* Variation and property drawer */}
      {showVariation && (
        <div className="kh-drawer">
          <div className="kh-drawer__container">
            <div className="kh-drawer__head py-3 my-3 d-flex align-items-center justify-content-between">
              <span className="kh-drawer__head-title text-capitalize">
                {_t(t("Add item to cart"))}
              </span>
              <button
                type="button"
                className="kh-drawer__close"
                onClick={() => {
                  setShowVariation(false);
                  setShowCart(false);
                  setShowCheckout(false);
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="kh-drawer__body_cart py-3" data-simplebar>
              <div className="kh-drawer__container">
                {/* old branch select option */}
                <div className="form-group gcspchangeeventonbranch">
                  <label htmlFor="branch" className="sm-text sp">
                    {_t(t("Select a branch"))}{" "}
                    {orderDetails.workPeriodStatus === true && (
                      <small className="text-danger">
                        {" "}
                        ( {_t(t("This branch is closed now"))})
                      </small>
                    )}
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      console.log("change branch");
                      // add delivery charge here
                      branchForSearch &&
                        branchForSearch.filter((item) => {
                          if (item.id == e.target.value) {
                            setBranchDeliveryCharge(
                              parseInt(item.delivery_fee)
                            );
                          }
                        });

                      //new order
                      setNewOrder({
                        variation: null,
                        quantity: 1,
                        properties: null,
                      });

                      let theWorkPeriod =
                        workPeriodWeb &&
                        workPeriodWeb.find((thisItem) => {
                          return (
                            parseInt(thisItem.branch_id) ===
                            parseInt(e.target.value)
                          );
                        });
                      if (theWorkPeriod !== undefined) {
                        setOrderDetails({
                          ...orderDetails,
                          items: [],
                          branch: e.target.value,
                          workPeriod: theWorkPeriod,
                          workPeriodStatus: false,
                        });
                      } else {
                        setOrderDetails({
                          ...orderDetails,
                          items: [],
                          branch: null,
                          workPeriod: null,
                          workPeriodStatus: true,
                        });
                      }
                    }}
                  >
                    {/*<option value="">{_t(t("Please select a branch"))}</option> */}
                    {branchForSearch &&
                      branchForSearch.map((eachBranch) => {
                        return (
                          <option
                            value={eachBranch.id}
                            selected={
                              eachBranch.id === parseInt(orderDetails.branch)
                            }
                          >
                            {eachBranch.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {/* old branch select option */}
                <div className="fk-sm-card__container mb-1">
                  <div className="fk-sm-card__content">
                    <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                      {foodItems.selectedItem.name}
                    </h6>
                  </div>
                </div>
                <ul className="kh-drawer__list">
                  <img
                    src={foodItems.selectedItem.image}
                    alt=""
                    className="img-fluid"
                  />

                  {/* Variations */}
                  {foodItems.variations && (
                    <>
                      {/* active variation price is completed */}
                      {/* <div className="fk-sm-card__container mb-1">
                        <div className="fk-sm-card__content">
                          <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                            {_t(t("Base Price"))}
                          </h6>
                        </div>

                        <span className="text-capitalize fk-badge">
                          {foodItems.selectedItem.price}
                        </span>
                      </div> */}
                      <div className="fk-sm-card__container mb-1">
                        <div className="fk-sm-card__content">
                          <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                            {_t(t("Variations"))}
                          </h6>
                        </div>

                        {/* <span className="text-capitalize xxsm-text fk-badge fk-badge--secondary">
                          {_t(t("Required"))}
                        </span> */}
                      </div>

                      {foodItems.variations.map(
                        (variationItem, varItemIndex) => {
                          return (
                            <li className="kh-drawer__list-item my-0 py-0">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="col">
                                  <label className="mx-checkbox flex-grow-1">
                                    <input
                                      type="checkbox"
                                      className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                      name="variation"
                                      onChange={() => {
                                        handleOrderItemVariation(variationItem);
                                      }}
                                      checked={
                                        newOrder.variation &&
                                        newOrder.variation
                                          .food_with_variation_id ===
                                          variationItem.food_with_variation_id
                                      }
                                    />
                                    <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8">
                                      {variationItem.variation_name}
                                    </span>
                                  </label>
                                </div>
                                <div className="col text-right">
                                  <span className="t-text-heading text-uppercase sm-text flex-grow-1">
                                    {currencySymbolLeft()}
                                    {formatPrice(
                                      variationItem.food_with_variation_price
                                    )}
                                    {currencySymbolRight()}
                                  </span>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      )}

                      {foodItems.properties && <hr />}
                    </>
                  )}

                  {foodItems.properties &&
                    foodItems.properties.map(
                      (propertyItem, propertyItemIndex) => {
                        let selectedGroup = null;
                        //property group
                        if (propertyItem.length > 0) {
                          selectedGroup =
                            propertyGroupWeb &&
                            propertyGroupWeb.find((singlePropertyGroup) => {
                              return (
                                singlePropertyGroup.id ===
                                parseInt(propertyItem[0].property_group_id)
                              );
                            });
                        }

                        return (
                          <>
                            <div className="fk-sm-card__container mb-1">
                              <div className="fk-sm-card__content">
                                <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                                  {selectedGroup && selectedGroup.name}
                                </h6>
                              </div>
                              <span className="text-capitalize xxsm-text fk-badge fk-badge--secondary">
                                {_t(t("Optional"))}
                              </span>
                            </div>
                            {propertyItem.map((eachItem) => {
                              return (
                                <li className="kh-drawer__list-item my-0 py-0">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="col">
                                      <label className="mx-checkbox flex-grow-1">
                                        <input
                                          type="checkbox"
                                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                          onChange={() => {
                                            handleOrderItemProperty(eachItem);
                                          }}
                                          checked={checkedProperty(eachItem)}
                                        />
                                        <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8">
                                          {eachItem.name}
                                        </span>
                                      </label>
                                    </div>
                                    <div className="col">
                                      <div className="fk-qty flex-grow-1 justify-content-end">
                                        {parseInt(
                                          eachItem.allow_multi_quantity
                                        ) === 1 && (
                                          <span
                                            className="fk-qty__icon fk-qty__deduct"
                                            onClick={() => {
                                              handlePropertyQty(eachItem, "-");
                                            }}
                                          >
                                            <i className="las la-minus"></i>
                                          </span>
                                        )}
                                        {parseInt(
                                          eachItem.allow_multi_quantity
                                        ) === 1 ? (
                                          <input
                                            type="text"
                                            value={checkCheckedPropertyQuantity(
                                              eachItem
                                            )}
                                            className="fk-qty__input t-bg-clear"
                                            readOnly
                                          />
                                        ) : (
                                          "-"
                                        )}
                                        {parseInt(
                                          eachItem.allow_multi_quantity
                                        ) === 1 && (
                                          <span
                                            className="fk-qty__icon fk-qty__add"
                                            onClick={() => {
                                              handlePropertyQty(eachItem, "+");
                                            }}
                                          >
                                            <i className="las la-plus"></i>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col text-right">
                                      <span className="t-text-heading text-uppercase sm-text flex-grow-1">
                                        {currencySymbolLeft()}
                                        {formatPrice(eachItem.extra_price)}
                                        {currencySymbolRight()}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                            {propertyItemIndex !==
                              foodItems.properties.length - 1 && <hr />}
                          </>
                        );
                      }
                    )}
                </ul>
              </div>
            </div>
            <div className="kh-drawer__foot py-3 my-3">
              <div className="kh-drawer__container">
                {/* <div className="d-flex align-items-center justify-content-between my-2">
                  <div className="kh-drawer__text text-capitalize">
                    subtotal
                  </div>
                  <div className="kh-drawer__text text-right text-uppercase sm-text">
                    bdt 16,70.00
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between my-2">
                  <div className="kh-drawer__text text-capitalize">Vat</div>
                  <div className="kh-drawer__text text-right text-uppercase sm-text">
                    bdt 12.00
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between my-2">
                  <div className="kh-drawer__text text-capitalize">total</div>
                  <div className="kh-drawer__text text-right text-uppercase sm-text">
                    bdt 1,682.00
                  </div>
                </div> */}
                {getCookie() === "undefined1" ? (
                  <NavLink to="/login" className="btn w-100 text-uppercase">
                    {_t(t("Login"))}
                  </NavLink>
                ) : (
                  <>
                    <button
                      className="btn w-100 text-uppercase sp"
                      onClick={handleOrder}
                    >
                      {_t(t("Add to cart"))}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Variation and property drawer ends */}

      {/* template content section */}
      <div className={foodItems.list ? "" : "d-none"}>
        <div className={loading ? "d-none" : ""}>
          <header id="home">
            <div
              className="homemainbannerbg"
              style={{
                backgroundImage: `url(${
                  getSystemSettings(generalSettings, "hero_image")
                    ? getSystemSettings(generalSettings, "hero_image")
                    : BASE_URL + "/images/hero/1650002283-banner-imgjpg.jpg"
                })`,
              }}
            >
              <div className="container">
                <div className="navecartsec">
                  <div className="menubarsec">
                    {" "}
                    <nav id="mobile-menu">
                      <ul className="main-menu main-menu2">
                        <li>
                          <a href="#home">{_t(t("Accueil"))}</a>
                        </li>
                        <li>
                          <a href="#popular">{_t(t("Boutique en ligne"))}</a>
                        </li>
                        <li>
                          <NavLink to="/nous-contacter">
                            {_t(t("Nous contacter"))}
                          </NavLink>
                        </li>

                        {deliverymenShow == true &&
                          authUserInfo &&
                          !authUserInfo.details && (
                            <li>
                              <NavLink to="/delivery-man-registration">
                                {_t(t("deliveryman"))}
                              </NavLink>
                            </li>
                          )}
                      </ul>
                    </nav>
                  </div>

                  <div className="hedlogincart">
                    <ul>
                      {getSystemSettings(generalSettings, "isshopactive") ==
                      1 ? (
                        <li>
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              setShowCart(true);
                            }}
                          >
                            <img
                              src="/website/images/icons/cart-icon.png"
                              alt=""
                            />
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {getCookie() === undefined ? (
                        <li>
                          <NavLink to="/login">
                            <img
                              src="/website/images/icons/user-icon.png"
                              alt=""
                            />
                          </NavLink>
                        </li>
                      ) : (
                        <>
                          {authUserInfo &&
                          authUserInfo.details &&
                          authUserInfo.details.user_type !== "customer" ? (
                            <li>
                              {" "}
                              <NavLink to="/dashboard">
                                <img
                                  src="/website/images/icons/user-icon.png"
                                  alt=""
                                />
                              </NavLink>
                            </li>
                          ) : (
                            <>
                              <li>
                                {" "}
                                <NavLink to="/profile">
                                  <img
                                    src="/website/images/icons/user-icon.png"
                                    alt=""
                                  />
                                </NavLink>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  onClick={handleLogout}
                                >
                                  <img
                                    src="/website/images/icons/user-logout.png"
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="mainlogo">
                  {" "}
                  {window.location.pathname === "/" ? (
                    <NavLink
                      to={{ pathname: "/refresh", state: "/" }}
                      exact
                      className="t-link w-100"
                      key="logokey"
                    >
                      <img
                        src={
                          getSystemSettings(generalSettings, "type_logo")
                            ? getSystemSettings(generalSettings, "type_logo")
                            : BASE_URL +
                              "/images/logo/1649746955-1618997935-logo2pngpng.png"
                        }
                        alt="logo 1"
                      />
                    </NavLink>
                  ) : (
                    <NavLink
                      to="/"
                      exact
                      className="t-link w-100"
                      key="logokey"
                    >
                      <img
                        src={getSystemSettings(generalSettings, "type_logo")}
                        alt="logo"
                      />
                    </NavLink>
                  )}
                </div>
                <div className="bannercontent">
                  <div className="bannercontentinner">
                    <h1>
                      {getSystemSettings(generalSettings, "hero_sub_1")
                        ? getSystemSettings(generalSettings, "hero_sub_1")
                        : "Boutique"}{" "}
                      <span>
                        {getSystemSettings(generalSettings, "hero_heading")
                          ? getSystemSettings(generalSettings, "hero_heading")
                          : "en  ligne"}
                      </span>
                    </h1>
                    <div className="banntext">
                      {getSystemSettings(generalSettings, "hero_sub_2")
                        ? getSystemSettings(generalSettings, "hero_sub_2")
                        : "Commmandez nos spécialités en Click and Collect"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>

        {/* <!-- menu-area --> */}
        <section
          className="menu-area home2-menu-area padding-top-50 padding-bottom-50"
          id="popular"
        >
          <div className="container">
            {/* <!-- menu-nav-wrapper --> */}
            <div className="menu-nav-wrapper">
              <div className="container">
                <div className="row">
                  <nav>
                    <div className="nav justify-content-center custom-nav">
                      {/* Food group */}
                      {foodGroupWeb &&
                        foodGroupWeb.map((groupItem, groupItemIndex) => {
                          return (
                            <a
                              href="javascript:void(0);"
                              className={`nav-item nav-link ${
                                foodItems.group &&
                                foodItems.group.id === groupItem.id
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                if (foodGroupWeb && foodListWeb) {
                                  let temp = foodListWeb.filter(
                                    (foodItem, foodItemIndex) => {
                                      return (
                                        parseInt(foodItem.food_group_id) ===
                                        groupItem.id
                                      );
                                    }
                                  );
                                  setFoodItems({
                                    ...foodItems,
                                    list: temp,
                                    group: groupItem,
                                  });
                                }
                              }}
                            >
                              <div className="single-menu-nav text-center">
                                <h6>
                                  <span>
                                    <img
                                      src={BASE_URL + groupItem.image}
                                      alt={groupItem.name}
                                    />
                                  </span>
                                  {groupItem.name}
                                </h6>
                              </div>
                            </a>
                          );
                        })}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            {/* <!-- menu-items-wrapper --> */}
            <div className="tab-content" id="nav-tabContent">
              {/* <!-- menu-items --> */}
              <div>
                <div className="menu-items-wrapper">
                  <div className="row" id="menus">
                    {foodItems.list &&
                      foodItems.list.map((foodItem, foodItemIndex) => {
                        return (
                          <div
                            className="col-xl-3 col-lg-4 col-md-6 col-sm-6 t-mt-30 sp"
                            key={foodItemIndex}
                          >
                            <div className="single-menu-item align-items-center h-100">
                              <div className="menu-img">
                                <img src={foodItem.image} alt="" />
                              </div>
                              <div className="menu-content">
                                <h5>
                                  <a className="pointer-cursor">
                                    {foodItem.name}
                                  </a>
                                </h5>
                                <p>
                                  {showManageStock
                                    ? [_t(t("Available Stock "))]
                                    : null}

                                  {showManageStock
                                    ? orderDetails.branch === null
                                      ? _t(
                                          t(
                                            "To check stock select branch after clicking this food"
                                          )
                                        )
                                      : handleGetStock(foodItem.id)
                                    : null}
                                </p>
                                <p>{foodItem.description}</p>
                                <span>
                                  {parseInt(foodItem.has_variation) !== 1 &&
                                    currencySymbolLeft() +
                                      formatPrice(foodItem.priceincludetax) +
                                      currencySymbolRight()}
                                </span>
                                {/* add to cart */}
                                {getSystemSettings(
                                  generalSettings,
                                  "isshopactive"
                                ) == 1 ? (
                                  <a
                                    className="gcsp-addtocart"
                                    onClick={() => {
                                      setFoodItems({
                                        ...foodItems,
                                        selectedItem: foodItem,
                                        variations:
                                          parseInt(foodItem.has_variation) === 1
                                            ? foodItem.variations
                                            : null,
                                        properties:
                                          parseInt(foodItem.has_property) === 1
                                            ? foodItem.properties
                                            : null,
                                      });
                                      setNewOrder({
                                        variation: null,
                                        quantity: 1,
                                        properties: null,
                                      });

                                      if (
                                        parseInt(foodItem.has_property) === 1
                                      ) {
                                        setShowVariation(true);
                                      } else {
                                        setTimeout(
                                          handleOrderSingle(foodItem),
                                          500
                                        );
                                      }
                                    }}
                                  >
                                    Ajouter au panier
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- footer area --> */}
        <footer
          className="padding-top-40 padding-bottom-40 footer2"
          id="language"
        >
          {/*  <div className="fo-shapes">
            <span className="fs-1 item-animateTwo">
              <img src="/website/images/shapes/capsicam.png" alt="" />
            </span>
            <span className="fss-2">
              <img src="/website/images/shapes/fshape1.png" alt="" />
            </span>
            <span className="fss-3">
              <img src="/website/images/shapes/41.png" alt="" />
            </span>
            <span className="fss-4 item-bounce">
              <img src="/website/images/shapes/sauce.png" alt="" />
            </span>
            <span className="fss-5 item-bounce">
              <img src="/website/images/shapes/scatter.png" alt="" />
            </span>
            <span className="fss-6 item-animateTwo">
              <img src="/website/images/shapes/layer.png" alt="" />
            </span>
          </div> */}
          {/* <div className="footer-top">
            <div className="container">
              <div className="row align-items-center justify-content-between pb-3">
                <div className="col-lg-6 col-md-12">
                  <div className="f-title">
                    <h4>
                      {_t(t("Set your"))} <span>{_t(t("local language"))}</span>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-between">
                    <div className="form2 ml-lg-auto">
                      <select
                        className="langSelect"
                        onChange={handleDefaultLang}
                      >
                        {navLanguageList &&
                          navLanguageList.map((item, index) => {
                            return (
                              <option
                                key={index}
                                className={`dropdown-item sm-text text-capitalize`}
                                value={item.id}
                                selected={
                                  defaultLang && item.code === defaultLang.code
                                }
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row align-items-center justify-content-between padding-bottom-45">
                <div className="col-lg-6 col-md-12">
                  <div className="f-title">
                    <h4>
                      {_t(t("Set your"))}{" "}
                      <span className="text-success">
                        {_t(t("local currency"))}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-between">
                    <div className="form2 ml-lg-auto">
                      <select
                        className="langSelect"
                        onChange={handleDefaultCurrency}
                      >
                        {navCurrencyList &&
                          navCurrencyList.map((item, index) => {
                            return (
                              <option
                                key={index}
                                className={`dropdown-item sm-text text-capitalize`}
                                value={item.id}
                                selected={
                                  defaultCurrency &&
                                  item.code === defaultCurrency.code
                                }
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div> */}
          <div className="copyright-area text-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 justify-content-self-center">
                  <div className="copyright-content">
                    <div className="f-logo">
                      <NavLink
                        to={{ pathname: "/refresh", state: "/" }}
                        exact
                        className="t-link w-100"
                        key="logokey"
                      >
                        <img
                          src={getSystemSettings(generalSettings, "type_logo")}
                          alt="logo"
                        />
                      </NavLink>
                    </div>
                    <div className="footer-nav text-center">
                      <nav>
                        <ul className="main-menu main-menu2">
                          <li>
                            <a href="#home">{_t(t("Accueil"))}</a>
                          </li>
                          <li>
                            <a href="#popular">{_t(t("Boutique"))}</a>
                          </li>
                          <li>
                            <NavLink to="/nous-contacter">
                              {_t(t("Nous contacter"))}
                            </NavLink>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={getSystemSettings(
                                generalSettings,
                                "facebook"
                              )}
                            >
                              <img
                                src="/website/images/icons/facebook-icon.png"
                                alt=""
                              />
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <p>
                      {getSystemSettings(generalSettings, "type_footer")} -{" "}
                      <NavLink to="/mentions-legales">
                        {_t(t("Mentions légales"))}
                      </NavLink>{" "}
                      -{" "}
                      <NavLink to="/conditions-generales-de-vente">
                        {_t(t("Terms of Sales"))}
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {/* <!-- ToTop Button --> */}
        <button
          className="scrollup"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <i className="fas fa-angle-up"></i>
        </button>
      </div>
      {/* template content end here */}
    </>
  );
};

export default RestaurantLanding;
