import React, { useContext, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import {
  _t,
  getCookie,
  getSystemSettings,  
} from "../../../functions/Functions";

import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//3rd party packages
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../contexts/Settings";
import "react-toastify/dist/ReactToastify.css";
//jQuery initialization
import $ from "jquery";
const ContactUs = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let {   generalSettings, } =  useContext(SettingsContext);
  const [loading, setLoading] = useState(true);
  const [contactContent, setContactContent] = useState({
    contacttitle: getSystemSettings(generalSettings, "contacttitle"),
    contactcontent: getSystemSettings(generalSettings, "contactcontent"),
    contacttime: getSystemSettings(generalSettings, "contacttime"),
    contactimage: getSystemSettings(generalSettings, "contactimage"),
    contactaddress: getSystemSettings(generalSettings, "contactaddress"),
    contactfacilityone: getSystemSettings(
      generalSettings,
      "contactfacilityone"
    ),
    contactfacilitytwo: getSystemSettings(
      generalSettings,
      "contactfacilitytwo"
    ),
    contactadminemail: getSystemSettings(generalSettings, "contactadminemail"),
    contactmap: getSystemSettings(generalSettings, "contactmap"),
  });

const [contactForm, setContactForm] = useState({
    name: null,        
    email:null,        
    subject: null,        
    message: null,            
});

// onchange function
const handleOnChange = (e) => {
    e.persist();
    setContactForm({
        ...contactForm,
        [e.target.name]: e.target.value
    })
}

useEffect(() => {
    
    const url = BASE_URL + `/settings/general-settings`;
    axios.get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    }).then((res) => {        
        setContactContent({
          contacttitle: getSystemSettings(res.data, "contacttitle"),
          contactcontent: getSystemSettings(res.data, "contactcontent"),
          contacttime: getSystemSettings(res.data, "contacttime"),
          contactimage: getSystemSettings(res.data, "contactimage"),
          contactaddress: getSystemSettings(res.data, "contactaddress"),
          contactfacilityone: getSystemSettings(res.data, "contactfacilityone"),
          contactfacilitytwo: getSystemSettings(res.data, "contactfacilitytwo"),
          contactadminemail: getSystemSettings(res.data, "contactadminemail"),
          contactmap: getSystemSettings(res.data, "contactmap"),
        });
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    });
    
}, []);

const handlesubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();        
    formData.append("name", contactForm.name);
    formData.append("email", contactForm.email);
    formData.append("subject", contactForm.subject);
    formData.append("message", contactForm.message);    
    
    const url = BASE_URL + "/website/sentcontactmail";
    axios.post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
    }).then((res) => {
        if (res.data.status == 200) {
            toast.success(`${_t(t("Email sent successfully"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
            $('#contact-form').trigger('reset');

        } else if (res.data.status == 422) {
            toast.error(`${_t(t("Try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });

        }
    });
};

  return (
    <>
      <div className={`preloader02 ${!loading && "d-none"}`} id="preloader02">
        <div className="preloader-inner">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
      <main className="kh-user pt-5 mb-none menu-area home2-menu-area ${loading?'d-none':''}">
        <div className="container">
          <section class="mb-4">
            <div class="gc_contacttitle">
              <h2 class="h1-responsive font-weight-bold text-center my-4">
                {contactContent.contacttitle}
              </h2>

              <p
                class="text-center w-responsive mx-auto"
                dangerouslySetInnerHTML={{
                  __html: contactContent.contactcontent,
                }}
              ></p>
            </div>
            <div class="row">
              <div class="col-lg-4 mb-md-0 mb-5 ">
                <div class="gcspcontact">
                  <form
                    id="contact-form"
                    onSubmit={handlesubmit}
                    name="contact-form"
                    method="POST"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-2">
                          <label for="name" class="">
                            {_t(t("Name"))}
                          </label>
                          <input
                            required
                            onChange={handleOnChange}
                            type="text"
                            id="name"
                            name="name"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="md-form mb-2">
                          <label for="email" class="">
                            {_t(t("Email"))}
                          </label>
                          <input
                            required
                            onChange={handleOnChange}
                            type="email"
                            id="email"
                            name="email"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-2">
                          <label for="subject" class="">
                            {_t(t("Subject"))}
                          </label>
                          <input
                            required
                            onChange={handleOnChange}
                            type="text"
                            id="subject"
                            name="subject"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form mb-2">
                          <label for="message">{_t(t("Your message"))}</label>
                          <textarea
                            required
                            onChange={handleOnChange}
                            type="text"
                            id="message"
                            name="message"
                            rows="2"
                            class="form-control md-textarea"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="text-center text-md-left">
                      <button class="btn btn-primary px-5" type="submit">
                        {_t(t("Send"))}
                      </button>
                    </div>
                  </form>
                </div>
                <div class="status"></div>
              </div>
              <div class="col-lg-4 mb-md-0 mb-5 ">
                <img
                  src={
                    contactContent.contactimage
                      ? BASE_URL + contactContent.contactimage
                      : BASE_URL +
                        "/images/logo/1649746955-1618997935-logo2pngpng.png"
                  }
                />
              </div>

              <div class="col-lg-4 text-center gcspcontactright">
                <div class="gcspcontactinner">
                  <ul class="list-unstyled mb-0">
                    <li>
                      <i class="fas fa-clock-o fa-2x"></i>
                      <p>{contactContent.contacttime}</p>
                    </li>
                    <li>
                      <i class="fas fa-check fa-2x"></i>
                      <p>{contactContent.contactfacilityone}</p>
                    </li>
                    <li class="gc_conadd">
                      <i class="fas fa-map-marker-alt fa-2x"></i>
                      <p>{contactContent.contactaddress}</p>
                    </li>
                    <li>
                      <i class="fas fa-check fa-2x "></i>
                      <p>{contactContent.contactfacilitytwo}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="gcspcontactmap">
          <div
            class="gcspmapinner"
            dangerouslySetInnerHTML={{ __html: contactContent.contactmap }}
          />
        </div>
      </main>
    </>
  );
};

export default ContactUs;
