import React, { useContext } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { _t, getCookie, getSystemSettings } from "../../functions/Functions";
import { useTranslation } from "react-i18next";
//context consumer
import { SettingsContext } from "../../contexts/Settings";

const CusFooter = () => {
  //getting context values here
  const { t, i18n } = useTranslation();
  let { generalSettings } = useContext(SettingsContext);
  
  return (
    <>
      <footer
        className="padding-top-40 padding-bottom-40 footer2"
        id="language"
      >
        <div className="copyright-area text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 justify-content-self-center">
                <div className="copyright-content">
                  <div className="f-logo">
                    <NavLink
                      to={{ pathname: "/refresh", state: "/" }}
                      exact
                      className="t-link w-100"
                      key="logokey"
                    >
                      <img
                        src={getSystemSettings(generalSettings, "type_logo")}
                        alt="logo"
                      />
                    </NavLink>
                  </div>
                  <div className="footer-nav text-center">
                    <nav>
                      <ul className="main-menu main-menu2">
                        <li>
                          <NavLink to="/#home">{_t(t("Accueil"))}</NavLink>
                        </li>
                        <li>
                          <NavLink to="/#popular">
                            {_t(t("Boutique"))}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/nous-contacter">
                            {_t(t("Nous contacter"))}
                          </NavLink>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href={getSystemSettings(
                              generalSettings,
                              "facebook"
                            )}
                          >
                            <img
                              src="/website/images/icons/facebook-icon.png"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <p>
                    {getSystemSettings(generalSettings, "type_footer")} -{" "}
                    <NavLink to="/mentions-legales">
                      {_t(t("Mentions légales"))}
                    </NavLink>{" "}
                    -{" "}
                    <NavLink to="/conditions-generales-de-vente">
                      {_t(t("Terms of Sales"))}
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <button
        className="scrollup"
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
      >
        <i className="fas fa-angle-up"></i>
      </button>
    </>
  );
};

export default withRouter(CusFooter);
