import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";


//importing context consumer here



//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../BaseUrl";

//functions
import {
  _t,  
} from "../../../functions/Functions";


import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";

//jQuery initialization
import $ from "jquery";

const MentionsLegales = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [showPageData, setshowPageData] = useState('');
  const getPageData = (e) => {
    setLoading(true);
    const url = BASE_URL + `/website/pages/mentions-legales`;
    return axios
      .get(url)
      .then((res) => {
        setshowPageData(res.data[0]);
        
      })
  }

  useEffect(() => {
    getPageData();
  });

  return (
    <>

      <main className="kh-user py-5 menu-area home2-menu-area">
        <div className="container">
          <div class="gc_contentpage">
          <div className="row">
            <div className="col-md-12">              
              <h1>{showPageData.name}</h1>
              <hr/>
              <div dangerouslySetInnerHTML={{ __html: showPageData.content}} >           
           </div>        
            </div>
            </div>
          </div>
        </div>
      </main>
     

    </>
  );
};

export default MentionsLegales;
